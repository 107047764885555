/**
 * @fileoverview Slides WebAPI の Repository です。
 * Slides に関するCRUD操作を定義します。
 */
import Repository from '../BaseRepository'
import setup from '../interceptors/Interceptors'

const resource = '/slides'

const interceptorManager = Repository.interceptors.request
console.log('interceptorManager(slidesRepository)', interceptorManager);
const id = interceptorManager.handlers.length
console.log('interceptorManager(slidesRepository:id)', id);
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor(slidesRepository)', Repository);
}

export default {

  /**
   * スライドの作成・出力
   *
   * @param id Get target projectId
   * @param payload Post Parameter
   * @returns {*}
   */
  export( id, payload ) {
    console.log( 'export', Repository, '\n', 'Parameters:', id, '\n', 'Body:', payload );
    return Repository.post( `${resource}/${id}/export`, payload );
  },


  templates() {
    return Repository.get( `${resource}/templates` );
  },

  export2( id, payload ) {
    console.log( 'export2', Repository, '\n', 'Parameters:', id, '\n', 'Body:', payload );
    return Repository.post( `${resource}/${id}/export2`, payload );
  },
}
