import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import snackbarPlugin from './plugins/snackbar';
import mixin from './utils/mixin';
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'
import VueCookies from 'vue-cookies'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

Vue.use(VueTheMask)
Vue.use(money, {thousands: ',', precision: 0})
Vue.use(VueCookies)
Vue.$cookies.config('30d')

dayjs.locale('ja')
Vue.prototype.$dayjs = dayjs

//Vue.use(axios)
Vue.use(VueAxios, axios)
Vue.use(snackbarPlugin, { store })

Vue.mixin(mixin);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
