import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        btPrimary: "#0761B4",
        btAccent: "#CD5410",
        btCancel: "#C4C4C4",
      },
      dark: {
        btPrimary: "#0761B4",
        btAccent: "#CD5410",
        btCancel: "#C4C4C4",
      },
    },
  },
});
