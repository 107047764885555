/**
 * @fileoverview OGP情報 WebAPI の Repository です。
 * OGP情報 に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'

const resource = '/ogp'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
    setup(Repository);
    console.log('Setup Interceptor', Repository);
}

export default {

    /**
     * OGP情報 の取得
     *
     * @param params Get Parameter
     * @returns {*}
     */
    getOgp(params) {
        return Repository.get(`${resource}`, params)
    },

}
