/**
 * @fileoverview Repository の基底です。
 * 変更の必要の無い部分をベースの URL として axiosの インスタンス生成時に渡しています。
 */
import axios from 'axios'

const baseURL    = `${process.env.VUE_APP_API_DOMAIN}`
const apiVersion = `${process.env.VUE_APP_API_VERSION}`

export default axios.create({
  baseURL: baseURL + apiVersion
})
