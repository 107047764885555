/**
 * @fileoverview 3C分析 WebAPI の Repository です。
 * 3C分析 に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
    setup(Repository);
    console.log('Setup Interceptor', Repository);
}

export default {

    /**
     * 3C分析 の取得
     *
     * @param id Get target projectId
     * @returns {*}
     */
    fetchedThreeCsAnalysis(id) {
        console.log('fetchedThreeCsAnalysis', Repository.get, '\n', 'Parameters:', id)
        return Repository.get(`${resource}/${id}/three_cs_analysis`)
    },

    /**
     * 3C分析 の更新
     *
     * @param projectId Update target projectId
     * @param threeCsAnalysisUpdateRequest Post Request Parameter
     * @returns {*}
     */
    updated(projectId, threeCsAnalysisUpdateRequest) {
        const payload =
          threeCsAnalysisUpdateRequest.name === 'ThreeCsAnalysisUpdateRequest'
            ? JSON.stringify(threeCsAnalysisUpdateRequest) : threeCsAnalysisUpdateRequest;
        console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
        return Repository.post(`${resource}/${projectId}/three_cs_analysis`, payload)
    },

}
