/**
 * @fileoverview 共通処理をまとめるファイルです。
 */

export default {
  methods: {
    /**
     * パーツ参照エリアでの項目表示の可否を判定します(1項目).
     * @param {*} refMode
     * @param {*} value 対象データ
     * @returns 
     */
    isShowRef(refMode, value) {
      if (value !== null && typeof value === "object") {
        return !refMode || value.length;
      } else if (typeof value !== Number) {
        // 意図的に入力した 0 が非表示になる可能性があるので Number の場合はデータに関わらず表示する
        return !refMode || value;
      }
    },

    /**
     * パーツ参照エリアでの項目表示の可否を判定します(複数項目).
     * @param {*} refMode 
     * @param {*} values 対象データの配列
     * @returns 
     */
    isShowRefs(refMode, values = []) {
      for (let value of values) {
        if (this.isShowRef(refMode, value)) return true;
      }
      return false;
    },

    /**
     * '/strategy'の中のリンクにおいて、現在のページがreadonlyであれば
     * リンク先もreadonlyになるようqueryparamを返却する
     * router-linkのtoプロパティ内で呼び出す想定
     */
    getQueryParamProjectReadonly() {
      let rtn = {}
      if(this.$route.query?.mode == "readonly") {
        rtn.mode = "readonly"
      }
      return rtn;
    }
  },
};
