/**
 * @fileoverview ユーザ理解 WebAPI の Repository です。
 * ユーザ理解 に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
    setup(Repository);
    console.log('Setup Interceptor', Repository);
}

export default {

    /**
     * ユーザ理解情報 の取得
     *
     * @param projectId Get target projectId
     * @returns {*}
     */
    fetchedUserinsightInfo(projectId) {
        console.log('fetchedUserinsightInfo:', Repository.get, '\nParameters:', projectId)
        return Repository.get(`${resource}/${projectId}/userinsight_info`)
    },

    /**
     * ユーザ理解情報 の更新
     *
     * @param projectId Update target projectId
     * @param userinsightInfoUpdateRequest Post Request Parameter
     * @returns {*}
     */
    updated(projectId, userinsightInfoUpdateRequest) {
        const payload =
          userinsightInfoUpdateRequest.name === 'UserinsightInfoUpdateRequest'
            ? JSON.stringify(userinsightInfoUpdateRequest) : userinsightInfoUpdateRequest;
        console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
        return Repository.post(`${resource}/${projectId}/userinsight_info`, payload)
    },

}
