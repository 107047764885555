/**
 * @fileoverview BP情報 WebAPI の Repository です。
 * BP情報 に関するCRUD操作を定義します。
 */
import Repository from '../BaseRepository'
import setup from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor', Repository);
}

export default {

  /**
   * BP情報 の取得
   *
   * @param projectId Get target projectId
   * @returns {*}
   */
  fetchedBrandInfo(projectId) {
    console.log('fetchedBrandInfo:', Repository.get, '\nParameters:', projectId)
    return Repository.get(`${resource}/${projectId}/brand_info`)
  },

  /**
   * BP情報 の更新
   *
   * @param projectId Update target projectId
   * @param brandInfoUpdateRequest Post Request Parameter
   * @returns {*}
   */
  updated(projectId, brandInfoUpdateRequest) {
    const payload =
      brandInfoUpdateRequest.name === 'BrandInfoUpdateRequest'
        ? JSON.stringify(brandInfoUpdateRequest) : brandInfoUpdateRequest;
    console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
    return Repository.post(`${resource}/${projectId}/brand_info`, payload)
  },

}
