import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Store from '../store/index.js'
import {RepositoryFactory} from '../api/RepositoryFactory.js'

Vue.use(VueRouter)

const routes = [
  //########## page ##########
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { isPublic: true },
  },
  {
    path: '',
    component: () => import("../components/atomic/templates/MainLayout.vue"),
    children: [
      {
        path: '/search',
        name: 'SearchPage',
        component: () => import('../views/ProjectSearchPage.vue')
      },
      {
        path: '/edit',
        name: 'ProjectEdit',
        component: () => import('../views/ProjectEditPage.vue'),
        props: route => ({ queryProjectId: route.query.p })
      },
      {
        path: '/top',
        name: 'TopPage',
        component: () => import('../views/TopPage.vue')
      },

      //##### マスタメンテ #####
      {
        path: 'master',
        component: () => import("../components/atomic/templates/MasterMenteLayout.vue"),
        children: [
          {
            path: 'user',
            name: 'MasterMente_user',
            component: () => import('../views/master/UserMasterListPage.vue'),
          },
          {
            path: 'department',
            name: 'MasterMente_department',
            component: () => import('../views/master/DepartmentMasterListPage.vue'),
          },
          {
            path: 'selectoritem',
            name: 'MasterMente_selectoritem',
            component: () => import('../views/master/SelectorItemMasterPage.vue'),
          },
        ]
      },

    ]
  },
  {
    path: "/strategy/:projectId",
    name: "NewStrategyEditPage",
    component: () => import("../views/NewStrategyEditPage.vue"),
    props: route => ({ query: route.query.readonly }),
    children: [
      {
        path: "/",
        name: "StrategyTopPage",
        component: () => import("../components/atomic/organisms/StrategyTop.vue"),
      },
      {
        path: "parts-list",
        name: "StrategyPartsAddPage",
        // component: () => import("../components/atomic/organisms/StrategyPartsAdd.vue"),
        component: () => import("../components/atomic/organisms/StrategyPartsAddDialog.vue"),
      },
      {
        path: "edit/:partsName",
        name: "StrategyPartsEditPage",
        component: () => import("../components/atomic/organisms/StrategyPartsEdit.vue"),
        props: true,
      },
      {
        path: "edit2/:partsName",
        name: "StrategyPartsEditPage2",
        component: () => import("../components/atomic/organisms/StrategyPartsEdit2.vue"),
        props: true,
      },
      {
        path: "edit3/:partsName",
        name: "StrategyPartsEditPage3",
        component: () => import("../components/atomic/organisms/StrategyPartsEdit3.vue"),
        props: true,
      },
      {
        path: "edit4/:partsName",
        name: "StrategyPartsEditPage4",
        component: () => import("../components/atomic/organisms/StrategyPartsEdit4.vue"),
        props: true,
      },
      {
        path: "edit5/:partsName",
        name: "StrategyPartsEditPage5",
        component: () => import("../components/atomic/organisms/StrategyPartsEdit5.vue"),
        props: true,
      },
      {
        path: "edit6/:partsName",
        name: "StrategyPartsEditPage6",
        component: () => import("../components/atomic/organisms/StrategyPartsEdit6.vue"),
        props: true,
      },
      {
        path: 'project/edit',
        name: 'ProjectEditInsideFrame',
        component: () => import('../components/atomic/organisms/ProjectEditInsideFrame.vue'),
        props: true,
      },
      {
        path: 'overview',
        name: 'StrategyOverview',
        component: () => import('../components/atomic/organisms/OverviewList.vue'),
        props: true,
      },
      {
        path: 'overview/edit',
        name: 'OverviewEdit',
        component: () => import('../components/atomic/organisms/StrategyOverview.vue'),
        props: route => ({ queryOverviewId: route.query.i }),
      },
      {
        path: 'review',
        name: 'ProjectReview',
        component: () => import('../components/atomic/organisms/ProjectReview.vue'),
      },
      {
        path: 'review/overview',
        name: 'ReviewOverview',
        component: () => import('../components/atomic/organisms/ReviewNote.vue'),
        props: route => ({ review: true }),
      },
      {
        path: 'project_log',
        name: 'ProjectLog',
        component: () => import('../components/atomic/organisms/ProjectLog'),
      }
    ],
  },

  {
    path: '/strategyold',
    name: 'StrategyEditPage',
    component: () => import('../views/StrategyEditPage.vue'),
    props: route => ({ queryProjectId: route.query.p }),
  },
  {
    path: '/strategyref',
    name: 'StrategyEditPage',
    component: () => import('../views/StrategyEditPage.vue'),
    props: route => ({ queryProjectId: route.query.p, readonly: true}),
  },

  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('../views/LoginPage.vue'),
    meta: { isPublic: true },
  },

  //########## page(sample) ##########
  {
    path: '/SampleEditorJs',
    name: 'SampleEditorJs',
    component: () => import('../views/sample/SampleEditorJs.vue')
  },
  
  {
    path: '/SampleStrategyPage2',
    name: 'SampleStrategyPage2',
    component: () => import('../views/sample/SampleStrategyPage2.vue')
  },
  {
    path: '/SampleCustomerJourneyMap',
    name: 'CustomerJourneyMap',
    component: () => import('../components/atomic/organisms/CustomerJourneyMap.vue')
  },
  {
    path: '/SamplePositioningMapEditor',
    name: 'PositioningMapEditor',
    component: () => import('../components/atomic/organisms/PositioningMapEditor.vue')
  },
  {
    path: '/CrossThreeCEditor',
    name: 'CrossThreeCEditor',
    component: () => import('../components/atomic/organisms/CrossThreeCEditor.vue'),
    props: route => ({ value: "{\n\"titles\":[\n{\n\"left\":140.94305351646906,\n\"top\":89.84444203172397,\n\"text\":\"市場×自社\"\n},\n{\n\"left\":588.0166672634725,\n\"top\":90.84221977503432,\n\"text\":\"市場×競合\"\n},\n{\n\"left\":387.7388795389314,\n\"top\":439.7555517641377,\n\"text\":\"自社×競合\"\n}\n],\n\"texts\":[\n{\n\"left\":13.009722570358946,\n\"top\":119.83333074827569,\n\"text\":\"ああああ\\nあああああああ\",\n\"width\":230,\n\"height\":34.17119999999999\n},\n{\n\"left\":558.9180526212604,\n\"top\":121.82888623489639,\n\"text\":\"いいい\\nいいい\\nいいい\\nいいい\\n\",\n\"width\":58.33312406682353,\n\"height\":89.22479999999997\n},\n{\n\"left\":277.7527689251584,\n\"top\":379.9444435827586,\n\"text\":\"ううううううう\",\n\"width\":98.00000000000001,\n\"height\":15.819999999999997\n}\n]\n}"}),
  },
  {
    path: '/SlideExportDialogMulti',
    name: 'SlideExportDialogMulti',
    component: () => import('../components/atomic/molecules/SlideExportDialogMulti.vue'),
    props: route => ({ value: true}),
  },
  {
    path: '/SampleSample2',
    name: 'Sample2',
    component: () => import('../views/sample/Sample2Page.vue')
  },
  {
    path: '/PartsImageRegister',
    name: 'PartsImageRegister',
    component: () => import('../views/sample/PartsImageRegister.vue')
  },
  {
    path: '/SampleTextareaDiv',
    name: 'SampleTextareaDiv',
    component: () => import('../views/sample/SampleTextareaDiv.vue')
  },

  //########## gallery page ##########
  {
    path: '/GalleryPage',
    name: 'Home',
    component: () => import('../views/GalleryPage.vue'),
    meta: { isPublic: true },
    children: [
      //########## sample view ##########
      {
        path: '/Sample2',
        name: 'Sample2',
        component: () => import('../views/sample/Sample2Page.vue')
      },
      {
        path: '/Sample3',
        name: 'Sample3',
        component: () => import('../views/sample/Sample3Page.vue')
      },
      {
        path: '/SampleProjectSummary',
        name: 'SampleProjectSummary',
        component: () => import('../views/sample/SampleProjectSummary.vue')
      },
      {
        path: '/SamplePersona',
        name: 'SamplePersona',
        component: () => import('../views/sample/SamplePersona.vue')
      },
      {
        path: '/SampleDesignImage',
        name: 'SampleDesignImage',
        component: () => import('../views/sample/SampleDesignImage.vue')
      },
      {
        path: '/SampleBpSheet',
        name: 'SampleBpSheet',
        component: () => import('../views/sample/SampleBpSheet.vue')
      },
      {
        path: '/SectionStatusImage',
        name: 'SectionStatusImage',
        component: () => import('../views/sample/SectionStatusImage.vue')
      },
      {
        path: '/SampleSectionPanel',
        name: 'SampleSectionPanel',
        component: () => import('../views/sample/SampleSectionPanel.vue')
      },

      //########## atoms ##########
      {
        path: '/VTextareaWithLink',
        name: 'VTextareaWithLink',
        component: () => import('../components/atomic/atoms/VTextareaWithLink.vue')
      },
      {
        path: '/VExpansionPanelHeaderEx',
        name: 'VExpansionPanelHeaderEx',
        component: () => import('../components/atomic/atoms/VExpansionPanelHeaderEx.vue')
      },
      {
        path: '/VExpansionPanelHeaderWithLabel',
        name: 'VExpansionPanelHeaderWithLabel',
        component: () => import('../components/atomic/atoms/VExpansionPanelHeaderWithLabel.vue')
      },
      {
        path: '/VListItemWithLink',
        name: 'VListItemWithLink',
        component: () => import('../components/atomic/atoms/VListItemWithLink.vue')
      },
      {
        path: '/ImageSelectDialog',
        name: 'ImageSelectDialog',
        component: () => import('../components/atomic/atoms/ImageSelectDialog.vue'),
        props: {value: true}
      },
      
      //########## molecules ##########
      {
        path: '/ReferenceSite',
        name: 'ReferenceSite',
        component: () => import('../components/atomic/molecules/ReferenceSite.vue')
      },
      {
        path: '/PersonaAdd',
        name: 'PersonaAdd',
        component: () => import('../components/atomic/molecules/PersonaAdd.vue')
      },
      {
        path: '/LogoAdd',
        name: 'LogoAdd',
        component: () => import('../components/atomic/molecules/LogoAdd.vue')
      },
      {
        path: '/ReferenceImage',
        name: 'ReferenceImage',
        component: () => import('../components/atomic/molecules/ReferenceImage.vue')
      },
      {
        path: '/ThemeColors',
        name: 'ThemeColors',
        component: () => import('../components/atomic/molecules/ThemeColors.vue')
      },
      {
        path: '/UserInformation',
        name: 'UserInformation',
        component: () => import('../components/atomic/molecules/UserInformation.vue')
      },
      {
        path: '/UserIcon',
        name: 'UserIcon',
        component: () => import('../components/atomic/molecules/UserIcon.vue')
      },
      {
        path: '/VCardTextWithLabel',
        name: 'VCardTextWithLabel',
        component: () => import('../components/atomic/molecules/VCardTextWithLabel.vue')
      },
      {
        path: '/SectionStatus',
        name: 'SectionStatus',
        component: () => import('../components/atomic/molecules/SectionStatus.vue')
      },
      {
        path: '/SheetStatus',
        name: 'SheetStatus',
        component: () => import('../components/atomic/molecules/SheetStatus.vue')
      },
      {
        path: '/AddHearingItems',
        name: 'AddHearingItems',
        component: () => import('../components/atomic/molecules/AddHearingItems.vue')
      },
      {
        path: '/ProjectCard',
        name: 'ProjectCard',
        component: () => import('../components/atomic/molecules/ProjectCard.vue')
      },
      {
        path: '/ThumbsUpInput',
        name: 'ThumbsUpInput',
        component: () => import('../components/atomic/molecules/ThumbsUpInput.vue')
      },
      {
        path: '/SlideExportDialog',
        name: 'SlideExportDialog',
        component: () => import('../components/atomic/molecules/SlideExportDialog.vue')
      },
      {
        path: '/SlideExportCompletedDialog',
        name: 'SlideExportCompletedDialog',
        component: () => import('../components/atomic/molecules/SlideExportCompletedDialog.vue')
      },
      //########## organisms ##########
      {
        path: '/CustomerJourneyMap',
        name: 'CustomerJourneyMap',
        component: () => import('../components/atomic/organisms/CustomerJourneyMap.vue')
      },
      {
        path: '/PositioningMapEditor',
        name: 'PositioningMapEditor',
        component: () => import('../components/atomic/organisms/PositioningMapEditor.vue')
      },
      {
        path: '/StrategyInfoSideMenu',
        name: 'StrategyInfoSideMenu',
        component: () => import('../components/atomic/organisms/StrategyInfoSideMenu.vue')
      },
      {
        path: '/PersonaInfo',
        name: 'PersonaInfo',
        component: () => import('../components/atomic/organisms/PersonaInfo.vue')
      },
      {
        path: '/ReferenceSites',
        name: 'ReferenceSites',
        component: () => import('../components/atomic/organisms/ReferenceSites.vue')
      },
      {
        path: '/ProjectDescription',
        name: 'ProjectDescription',
        component: () => import('../components/atomic/organisms/ProjectDescription.vue')
      },
      {
        path: '/ManegementPhilosophy',
        name: 'ManegementPhilosophy',
        component: () => import('../components/atomic/organisms/bp/ManegementPhilosophy.vue')
      },
      {
        path: '/CompanyProfile',
        name: 'CompanyProfile',
        component: () => import('../components/atomic/organisms/bp/CompanyProfile.vue')
      },
      {
        path: '/CompanyVision',
        name: 'CompanyVision',
        component: () => import('../components/atomic/organisms/bp/CompanyVision.vue')
      },
      {
        path: '/CurrentStrategyAndAction',
        name: 'CurrentStrategyAndAction',
        component: () => import('../components/atomic/organisms/bp/CurrentStrategyAndAction.vue')
      },
      {
        path: '/Service',
        name: 'Service',
        component: () => import('../components/atomic/organisms/bp/Service.vue')
      },
      {
        path: '/Competitors',
        name: 'Competitors',
        component: () => import('../components/atomic/organisms/bp/Competitors.vue')
      },
      {
        path: '/BottomPanel',
        name: 'BottomPanel',
        component: () => import('../components/atomic/organisms/BottomPanel.vue')
      },
      {
        path: '/StpAnalysis',
        name: 'StpAnalysis',
        component: () => import('../components/atomic/organisms/StpAnalysis.vue')
      },
      {
        path: '/ThreeCsAnalysis',
        name: 'ThreeCsAnalysis',
        component: () => import('../components/atomic/organisms/ThreeCsAnalysis.vue')
      },
      {
        path: '/SearchConditions',
        name: 'SearchConditions',
        component: () => import('../components/atomic/organisms/ProjectSearchConditions.vue')
      },
      {
        path: '/ProjectCards',
        name: 'ProjectCards',
        component: () => import('../components/atomic/organisms/ProjectCards.vue')
      },
      {
        path: '/ProjectEntryForm',
        name: 'ProjectEntryForm',
        component: () => import('../components/atomic/organisms/ProjectEntryForm.vue')
      },
      {
        path: '/SalesforceView',
        name: 'SalesforceView',
        component: () => import('../components/atomic/organisms/SalesforceView.vue')
      },
      {
        path: '/TargetUser',
        name: 'TargetUser',
        component: () => import('../components/atomic/organisms/TargetUser.vue')
      },
      {
        path: '/UserSelectDialog',
        name: 'UserSelectDialog',
        component: () => import('../components/atomic/organisms/UserSelectDialog.vue')
      },

      //########## templates ##########
    ],

  }

]

const isLocalmode = process.env.NODE_ENV === 'local'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (isLocalmode || to.matched.some(page => page.meta.isPublic) || Store.state.accessToken) {
      if (isLocalmode && !Store.getters.userInfo) {
        Store.dispatch('canLogin',{eMail: "aaa", password: "aaa"});
      }
      next()
  } else {
    if(to.path != '/login') {
      //ログイン画面以外

      //アクセストークンが有効期限内かチェック(有効期限時刻>=現在時刻)
      const nowDate = new Date();
      const expirationDate = new Date(localStorage.tokenExpirationDate);

      if (expirationDate.getTime() >= nowDate.getTime()) {
        //期限内ならアクセストークンからUserInfoを取得
        const repository = RepositoryFactory.get('login');
        const response = await repository.hasLogin();

        if (response.data.result && response.data.resultCode === '0000') {
          //UserInfo取得出来たら、UserInfoをストアへ保存し、該当画面へ遷移
          const userInfo = JSON.parse(JSON.stringify(response.data.data))
          Store.commit('userInfo', userInfo)

          next()
        } else {
          //NGなら、nextPageを保存し、ログイン画面へ遷移
          try {
            Store.commit('nextPage', to)
          } catch (error) {
            //ログに吐くものの次へ進む
            console.error(error)
          }

          next('/login')
        }

      } else {
        //NGなら、nextPageを保存し、ログイン画面へ遷移
        try {
          Store.commit('nextPage', to)
        } catch (error) {
          //ログに吐くものの次へ進む
          console.error(error)
        }

        next('/login')
      }

    } else {
      //ログイン画面ならそのまま遷移
      next('/login')
    }

  }
})

export default router
