/**
 * @fileoverview Salesforce 連携情報取得 WebAPI の Repository です。
 * Salesforce 連携情報取得 に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'

const resource = '/salesforce'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
    setup(Repository);
    console.log('Setup Interceptor', Repository);
}

export default {

    /**
     * Salesforce 連携情報 の取得
     *
     * @param params Get Parameter
     * @returns {*}
     */
    getSalesforce (params) {
        console.log('getSalesforce', Repository.get, 'Parameters:', params)
        return Repository.get(`${resource}`, {params: params})
    },

}
