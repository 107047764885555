import base from "./MasterData_base";

//マスタデータを表すオブジェクト（export default）
export default JSON.parse(JSON.stringify(base));

//選択項目のメタ情報
export const SelectorItemDefs = {
  industries: 
    { caption: "業界", itemType: "object", keyName: "industry", },
  businessPhases: 
    { caption: "フェーズ", itemType: "object", keyName: "businessPhase", },
  issues: 
    { caption: "課題分類", itemType: "object", keyName: "issue", },
  statuses: 
    { caption: "ステータス", itemType: "object", keyName: "status", },
  visionEvaluation: 
    { caption: "設計評価", itemType: "object", keyName: "visionEvaluation", },
  onlineMeasuresDentistry: 
    { caption: "歯科医", itemType: "object", keyName: "onlineMeasures", },
  onlineMeasuresRealEstate: 
    { caption: "不動産", itemType: "object", keyName: "onlineMeasures", },
  onlineMeasuresBuilding: 
    { caption: "住宅建築", itemType: "object", keyName: "onlineMeasures", },
  onlineMeasuresOther: 
    { caption: "その他", itemType: "object", keyName: "onlineMeasures", },
  offlineMeasuresDentistry: 
    { caption: "歯科医", itemType: "object", keyName: "offlineMeasures", },
  offlineMeasuresRealEstate: 
    { caption: "不動産", itemType: "object", keyName: "offlineMeasures", },
  offlineMeasuresBuilding: 
    { caption: "住宅建築", itemType: "object", keyName: "offlineMeasures", },
  offlineMeasuresOther: 
    { caption: "その他", itemType: "object", keyName: "offlineMeasures", },
  strategicEvaluation: 
    { caption: "戦略と施策評価", itemType: "object", keyName: "strategicEvaluation", },
  overviewCategory:
    { caption: "カテゴリー", itemType: "object", keyName: "overviewCategory", },
  stpDifferentiation:
    { caption: "差別化ポイント", itemType: "object", keyName: "stpDifferentiation", },
  snsAccount:
    { caption: "SNSアカウント", itemType: "array", keyName: "snsAccount", },
  locationCategory1:
    { caption: "立地属性1", itemType: "array" },
  locationCategory2:
    { caption: "立地属性2", itemType: "array" },
  locationCategory3:
    { caption: "立地属性3", itemType: "array" },
  locationCategory4:
    { caption: "立地属性4", itemType: "array" },
  serviceIndustries:
    { caption: "業態", itemType: "array" },
  serviceTags: 
    { caption: "その他", itemType: "array" },
  serviceTagsRealEstate: 
    { caption: "不動産", itemType: "array" },
  serviceTagsBuilding: 
    { caption: "住宅建築", itemType: "array" },
  ageOption: 
    { caption: "年齢層", itemType: "array" },
  incomeYearOption: 
    { caption: "年収(toC)", itemType: "array" },
  transportationTypeOption: 
    { caption: "交通手段", itemType: "array" },
  collectInfoOption: 
    { caption: "情報収集手段", itemType: "array" },
  jobOption: 
    { caption: "職業(toC)", itemType: "array" },
  jobPositionOption: 
    { caption: "役職(toB)", itemType: "array" },
  departmentOption: 
    { caption: "所属部署(toB)", itemType: "array" },
  amountRangeOption: 
    { caption: "売上高(toB)", itemType: "array" },
  employeeNumRangeOption: 
    { caption: "従業員数(toB)", itemType: "array" },
  officeNumRangeOption: 
    { caption: "拠点数(toB)", itemType: "array" },
  favoriteSnsOption: 
    { caption: "よく使うSNS", itemType: "array" },
  designFontItems: 
  { caption: "フォントイメージ", itemType: "array" },
  designKeywordItems: 
  { caption: "デザインキーワード", itemType: "array" },
  evaluationGoodFactor: 
  { caption: "Good要因", itemType: "array" },
  evaluationBadFactor: 
  { caption: "Bad要因", itemType: "array" },
  evaluationBtEvaluation: 
  { caption: "自社評価", itemType: "array" },
  evaluationClientEvaluation: 
  { caption: "クライアント評価", itemType: "array" },
};

//選択項目編集のtree定義
const def2TreeItem = key => ({
  id: key,
  name: SelectorItemDefs[key].caption
});

export const SelectorItemTreeDefs = [
  {
    name: "プロジェクト",
    children: [
      def2TreeItem("industries"),
      def2TreeItem("businessPhases"),
      def2TreeItem("issues"),
      def2TreeItem("statuses"),
      def2TreeItem("snsAccount"),
    ]
  },  
  {
    name: "戦略パーツ",
    children: [
      {
        name: "MVV",
        children: [
          def2TreeItem("visionEvaluation"),
        ]
      },
      {
        name: "戦略方針",
        children: [
          {
            name: "タッチポイント「オンライン」",
            children: [
              def2TreeItem("onlineMeasuresDentistry"),
              def2TreeItem("onlineMeasuresRealEstate"),
              def2TreeItem("onlineMeasuresBuilding"),
              def2TreeItem("onlineMeasuresOther"),
            ]
          },
          {
            name: "タッチポイント「オンライン」",
            children: [
              def2TreeItem("offlineMeasuresDentistry"),
            def2TreeItem("offlineMeasuresRealEstate"),
            def2TreeItem("offlineMeasuresBuilding"),
            def2TreeItem("offlineMeasuresOther"),
            ]
          },
          def2TreeItem("strategicEvaluation"),
        ]
      },
      {
        name: "サービス情報",
        children: [
          def2TreeItem("serviceIndustries"),
          def2TreeItem("locationCategory1"),
          def2TreeItem("locationCategory2"),
          def2TreeItem("locationCategory3"),
          def2TreeItem("locationCategory4"),
          {
            name: "サービスタグ",
            children: [
              def2TreeItem("serviceTagsRealEstate"),
              def2TreeItem("serviceTagsBuilding"),
              def2TreeItem("serviceTags"),
            ]
          }
        ]
      },
      {
        name: "STP分析",
        children: [
          def2TreeItem("stpDifferentiation"),
        ]
      },
      {
        name: "ペルソナ",
        children: [
          def2TreeItem("ageOption"),
          def2TreeItem("incomeYearOption"),
          def2TreeItem("transportationTypeOption"),
          def2TreeItem("collectInfoOption"),
          def2TreeItem("jobOption"),
          def2TreeItem("jobPositionOption"),
          def2TreeItem("departmentOption"),
          def2TreeItem("amountRangeOption"),
          def2TreeItem("employeeNumRangeOption"),
          def2TreeItem("officeNumRangeOption"),
          def2TreeItem("favoriteSnsOption"),
        ]
      },
      {
        name: "デザイン要件",
        children: [
          def2TreeItem("designFontItems"),
          def2TreeItem("designKeywordItems"),
        ]
      }
    ]
  },
  {
    name: "戦略ノート",
    children: [
      def2TreeItem("overviewCategory"),
    ]
  },
  {
    name: "振り返り",
    children: [
      def2TreeItem("evaluationGoodFactor"),
      def2TreeItem("evaluationBadFactor"),
      def2TreeItem("evaluationBtEvaluation"),
      def2TreeItem("evaluationClientEvaluation"),
    ]
  }
];

//便利関数
export function searchById( base, master, key, value ) {
  if ( base.hasOwnProperty( master ) ) {
    for( let k in base[master] ) {
       if ( base[master][k].id  == value ) {
         return base[master][k][key];
       }
    }
  }
}
