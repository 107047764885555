/**
 * @fileoverview AXIOS Inteceptor です。
 * AXIOS のリクエスト・レスポンスに関するCRUD操作を定義します。
 */
import store from '../../store/index'
import router from "../../router";

export default function setup(axios) {

  // console.log('setup', axios, localStorage);

  /**
   * Request Interceptor
   */
  axios.interceptors.request.use(function(config) {
    const tokenStore = localStorage.token;
    // Token が LocalStorage に設定されていれば Bearer HttpHeader: Authorization に設定する
    if  (tokenStore) {
      // X-Auth-Token ヘッダーを使う場合
      config.headers['X-Auth-Token'] = tokenStore;
      // Authorization ヘッダーを使う場合
      // config.headers.Authorization = `Bearer ${tokenStore}`;
    }
    const cookies = store.state.cookies;
    // Cookies が store に設定されていれば HttpHeader に設定する
    if  (cookies) {
      let cookieString = ""
      Object.keys(cookies).forEach(([key, value]) => {
        console.log(`key:${key} value:${value}`);
        if (cookieString) {
          cookieString = value
        } else {
          cookieString += ' ' + value
        }
      });
      config.headers.cookies = cookieString;
    }

    // ログ採取
    const url = `${config.baseURL}${config.url}`;
    const bodyString = config.data ? JSON.stringify(config.data) : "";
    console.log('%c --> ' + `Method=${config.method.toUpperCase()} Url=${url} Body=${bodyString}`, 'color: #0086b3; font-weight: bold');

    return config;
  }, function(error) {
    return Promise.reject(error);
  });

  /**
   * Response Inteceptor
   */
  axios.interceptors.response.use(
    function(response) {
      // if (typeof store !== 'undefined') {
      //     store.dispatch('setToastMessage', { msg: '200 が見つかりました😷' })
      // }

      // ログ採取
      // console.dir('<-- 200', response)
      const url = `${response.config.baseURL}${response.config.url}`;
      console.log('%c <-- ' + `Status=${response.status} Url=${url}`, 'color: #0086b3; font-weight: bold');

      // Response Header から必要な情報を取得
      if (response.headers.cookies) {
        // Cookie 情報を取得する
        store.state.cookies = response.headers.cookies
      }
      if (response.headers['x-auth-token']) {
        // token 情報を取得する
        console.log('x-auth-token', response.headers['x-auth-token'])
        localStorage.token = response.headers['x-auth-token']
      }
      //トークン有効期限を設定する
      let date = new Date();
      //3時間後
      date.setHours(date.getHours() + 3);
      console.log("tokenExpirationDate:", date);
      localStorage.tokenExpirationDate = date;

      return response
    },
    function(error) {
      // console.dir(error)
      // HttpStatus による処理
      if (error.response && error.response.status) {
        let msg = `通信エラーが発生しました(${error.response.status})`

        if (error.response.status === 401) {
          console.log('401 エラーが見つかりました😷\nログインへ飛ばす')
          router.push('/login')
          return Promise.reject(error)
        } else if(error.response.status === 404) {
          //該当ユーザー無しが404の為、致し方なく
          if(error.response.data.message == "User was not found.") {
            msg = '該当ユーザーが存在しない、またはパスワード誤りです'
          }

          //今今は404エラーだった旨で表示するのみ
        } else if (error.response.data) {
          const _data = error.response.data
          console.log(_data)
          msg = `通信エラーが発生しました\n(${_data})`
        }

        if (typeof store !== 'undefined') {
          store.dispatch('setToastMessage', {msg: msg, color: 'error', timeout: 4000})
        } else {
          alert(msg)
        }
      } else {
        // 例外を投げる
        throw error
      }

      return Promise.reject(error)
    }
  );

}
