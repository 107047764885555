/**
 * @fileoverview 戦略ノート情報 WebAPI の Repository です。
 * 戦略ノート情報 に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
    setup(Repository);
    console.log('Setup Interceptor', Repository);
}

export default {

    /**
     * 戦略ノート情報 の取得
     *
     * @param id Get target overviewId
     * @param projectId Get target projectId
     * @returns {*}
     */
     fetched(id, projectId) {
        return Repository.get(`${resource}/${projectId}/overview/${id}`)
    },

    /**
     * 戦略ノート情報 の更新
     *
     * @param id Update target overviewId
     * @param projectId Update target projectId
     * @param payload Post Request Parameter
     * @returns {*}
     */
    updated(id, projectId, payload) {
        return Repository.put(`${resource}/${projectId}/overview/${id}`, payload)
    },

    /**
     * 戦略ノート情報 の登録
     *
     * @param id Create target overviewId
     * @param projectId Create target projectId
     * @param payload Post Request Parameter
     * @returns {*}
     */
     create(projectId, payload) {
        return Repository.post(`${resource}/${projectId}/overview`, payload)
    },

    /**
     * 戦略ノート情報 の論理削除
     *
     * @param id Delete target overviewId
     * @param projectId Delete target projectId
     * @returns {*}
     */
     delete(id, projectId) {
        return Repository.delete(`${resource}/${projectId}/overview/${id}`)
    },

    /**
     * 戦略ノート情報 の一覧取得
     *
     * @param projectId Delete target projectId
     * @returns {*}
     */
     getList(projectId) {
        return Repository.get(`${resource}/${projectId}/overviews`)
    },

    /**
     * 戦略ノート情報 の複製
     * 
     * @param {*} id copy target overviewID
     * @param {*} projectId
     * @param payload Post Request Parameter
     * @returns 
     */
    copy(id, projectId, payload) {
        return Repository.post(`${resource}/${projectId}/overview/${id}/copy`, payload)
    }
}
