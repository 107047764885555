/**
 * @fileoverview 暗号化・復号 します。
 * node.js crypto を用いて文字列を暗号化します。
 * 又暗号化された文字列を復号します。
 */
import * as dotenv from 'dotenv'

var crypto = require("crypto");


// 環境変数にセットされている DB_ENV を格納する
var env = (process.env.NODE_ENV) ? process.env.NODE_ENV : 'local';
// 環境ごとにconfigファイルを読み込む
var envFile = '.env.' + env;

console.log('Loaded file:', __dirname + envFile);
dotenv.config({path: __dirname + envFile})

console.log(
  'load vars:',
  `${process.env.VUE_APP_API_DOMAIN}`,
  `${process.env.VUE_APP_API_VERSION}`,
  `${process.env.VUE_APP_ALGORITHM}`,
  `${process.env.VUE_APP_HEX_IV_STRING}`,
  `${process.env.VUE_APP_PW_SECRET}`,
  `${process.env.VUE_APP_SALT}`,
)

// AES アルゴリズム
const ALGO = process.env.VUE_APP_ALGORITHM || 'aes-256-cbc'

// 事前に共有すべきパスワード
// console.log(crypto.randomBytes(32).toString('base64'))
// encode encryption key from utf8 to hex
let ENCRYPTION_KEY = '';
if ((process.env.VUE_APP_PW_SECRET)?.indexOf('base64:') === 0) {
  ENCRYPTION_KEY = process.env.VUE_APP_PW_SECRET
    ? Buffer.from(
      process.env.VUE_APP_PW_SECRET.replace("base64:", ""), 'base64').toString('hex') : '';
} else {
  ENCRYPTION_KEY = process.env.VUE_APP_PW_SECRET ? Buffer.from(process.env.VUE_APP_PW_SECRET).toString('hex') : '';
}
// initialize key
const KEY = ENCRYPTION_KEY ? Buffer.from(ENCRYPTION_KEY, "hex") : '';

// 事前に共有すべき SALT
// console.log(crypto.randomBytes(16).toString('base64'))
const SALT = process.env.VUE_APP_VUE_APP_SALT || ''

// validate missing config options
if (!ALGO && !KEY) {
  throw Error('Configuration Error!')
}

  // 暗号化メソッド
export function encrypt(value, isInt = false) {

  // Validate missing value
  if (!value) {
    throw Error('A value is required!')
  }

  // IV を生成
  const ivValue = process.env.VUE_APP_HEX_IV_STRING
    ? process.env.VUE_APP_HEX_IV_STRING : crypto.randomBytes(16).toString('base64')
  const iv = Buffer.from(ivValue, "hex")

  // 暗号器を生成
  const cipher = crypto.createCipheriv(ALGO, KEY, iv)

  // Return Buffer as a binary encoded string
  let buffer = Buffer.from(value, 'utf8').toString("binary")

  // Support for small and big integers
  if (isInt) {
    // Set byte auto padding to true
    cipher.setAutoPadding(true);

    // allocate Buffer instance 8 bytes
    const buf = Buffer.allocUnsafe(8);

    // Write value to buf instance at the specified offset as big-endian.
    buf.writeBigUInt64BE(BigInt(value));

    // encode as binary
    buffer = buf.toString("binary");
  }

  // data を暗号化
  // Get encrypted data from the cipher instance
  const firstPart = cipher.update(buffer, "binary", "base64")
  const finalPart = cipher.final("base64")

  // concat and return both parts
  const encryptedData = `${firstPart}${finalPart}`
  const ivRetvalue    = iv.toString('base64')
  return {ivRetvalue, encryptedData}

}

  // 復号メソッド
export function decrypt(mIv, encryptedData, isInt = false) {

  // Validate missing value
  if (!encryptedData) {
    throw Error('A encryptedData is required!');
  }

  // IV を設定
  const ivValue = mIv ? mIv : process.env.VUE_APP_HEX_IV_STRING || ''
  const iv = Buffer.from(ivValue, "hex")

  // 復号器を生成
  const decipher = crypto.createDecipheriv(ALGO, KEY, iv);

  // Support for small and big integers
  if (isInt) {
    // Set byte auto padding to true
    decipher.setAutoPadding(true);
  }

  // encodes encrypted value from base64 to hex
  const buffer = Buffer.from(encryptedData, "base64").toString("hex");

  // Get decrypted data from decipher instance
  const firstPart = decipher.update(buffer, 'hex', 'base64');
  const finalPart = decipher.final('base64') || '';

  // concat both parts
  const decrypted = `${firstPart}${finalPart}`;

  // Encode decrypted value as a 64-bit Buffer
  const buf = Buffer.from(decrypted, "base64");

  // Support for small and big integers
  if (isInt) {
    // Reads an unsigned, big-endian 64-bit integer from buf at the specified offset
    // and returns as a string
    return buf.readBigUInt64BE(0).toString();
  }

  // convert decrypted value from base64 to utf-8 string
  return buf.toString('utf8');

}
