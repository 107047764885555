/**
 * @fileoverview PEST分析 WebAPI の Repository です。
 * PEST分析 に関するCRUD操作を定義します。
 */
import Repository from '../BaseRepository'
import setup from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor', Repository);
}

export default {

  /**
   * PEST分析 の取得
   *
   * @param id Get target projectId
   * @returns {*}
   */
  fetchedPestAnalysis(id) {
    console.log('fetchedPestAnalysis', Repository.get, '\n', 'Parameters:', id)
    return Repository.get(`${resource}/${id}/pest_analysis`)
  },

  /**
   * PEST分析 の更新
   *
   * @param projectId Update target projectId
   * @param pestAnalysisRequest Post Request Parameter
   * @returns {*}
   */
  updated(projectId, pestAnalysisRequest) {
    const payload =
      pestAnalysisRequest.name === 'PestAnalysisRequest'
        ? JSON.stringify(pestAnalysisRequest) : pestAnalysisRequest;
    console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
    return Repository.post(`${resource}/${projectId}/pest_analysis`, payload)
  },

}
