/**
 * @fileoverview Department WebAPI の Repository です。
 * Department に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'

const resource = '/departments'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
    setup(Repository);
    console.log('Setup Interceptor', Repository);
}

export default {

    /**
     * Department の取得
     *
     * @returns {*}
     */
    getDepartmentList() {
        console.log('getDepartmentList', Repository.get, 'Parameters:')
        return Repository.get(`${resource}`)
    },

	update(payload) {
		return Repository.post(`${resource}`, payload );
	},

	delete(id) {
		return Repository.delete(`${resource}/${id}`);
	}
}
