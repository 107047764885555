<template>
  <div v-if="show" class="d-flex flex-column align-center overflow-y-auto" style="height: 100vh;">
    <img alt="Vue logo" src="../assets/logo.png">

    <router-link to="/SampleEditorJs">ノート機能</router-link>

    <router-link to="/SampleStrategyPage2">BP画面（ヘッダ左）</router-link>
    <br>
    <router-link to="/SampleCustomerJourneyMap">カスタマージャーニーマップ</router-link>
    <br>
    <router-link to="/SamplePositioningMapEditor">ポジショニングマップ</router-link>
    <router-link to="/CrossThreeCEditor">クロス3C</router-link>
    <br>
    <router-link to="/PartsImageRegister">パーツ画像登録</router-link>
    <!-- <router-link to="/SampleTextareaDiv">テキストエリアdiv</router-link> -->
    <br>
    
    <router-link to="/SampleSample2">プロジェクト一覧 進捗表現の例</router-link>
    <br>
    <router-link to="/GalleryPage">GalleryPage</router-link>
    <br>
    <router-link :to="{ path: 'strategyold', query: { p: 1 }}">戦略情報登録ページ(弊社用)</router-link>
    <router-link :to="{ path: 'strategyold', query: { p: 2 }}">戦略情報登録ページ(石井さん用)</router-link>
    <router-link :to="{ path: 'strategyold', query: { p: 3 }}">戦略情報登録ページ(新井さん用)</router-link>
    <router-link :to="{ path: 'strategyold', query: { p: 4 }}">戦略情報登録ページ(澤田さん用)</router-link>
    <br>
    <router-link :to="{ name: 'StrategyTopPage', params: { projectId: 1 }}">新戦略情報登録ページ</router-link>
    <router-link :to="{ name: 'StrategyPartsEditPage2', params: { projectId: 1, partsName: 'philosophy' }}">戦略パーツ編集ページ(参照エリア案 1)</router-link>
    <router-link :to="{ name: 'StrategyPartsEditPage3', params: { projectId: 1, partsName: 'philosophy' }}">戦略パーツ編集ページ(参照エリア案 2)</router-link>
    <router-link :to="{ name: 'StrategyPartsEditPage4', params: { projectId: 1, partsName: 'philosophy' }}">戦略パーツ編集ページ(参照エリア案 3)</router-link>
    <router-link :to="{ path: 'strategy/1/edit5/philosophy' }">戦略パーツ編集ページ(参照エリア案 4)</router-link>
    <router-link :to="{ path: 'strategy/1/edit6/philosophy' }">戦略パーツ編集ページ(参照エリア案 5)</router-link>
    <br>
    <router-link to="/search">検索ページ</router-link>
    <router-link to="/top">トップページ</router-link>
    <router-link to="/login">ログインページ</router-link>

    
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    if(process.env.NODE_ENV == 'production') {
      this.$router.push("/login")
    } else {
      this.show = true
    }
  },
  methods: {
  },
}
</script>

