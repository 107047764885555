/**
 * @fileoverview ビジネスモデルキャンバス分析 WebAPI の Repository です。
 * ビジネスモデルキャンバス分析 に関するCRUD操作を定義します。
 */
import Repository from '../BaseRepository'
import setup from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor', Repository);
}

export default {

  /**
   * ビジネスモデルキャンバス分析 の取得
   *
   * @param id Get target projectId
   * @returns {*}
   */
  fetchedBusinessModelCanvas(id) {
    console.log('fetchedBusinessModelCanvas', Repository.get, '\n', 'Parameters:', id)
    return Repository.get(`${resource}/${id}/business_model_canvas`)
  },

  /**
   * ビジネスモデルキャンバス分析 の更新
   *
   * @param projectId Update target projectId
   * @param businessModelCanvasRequest Post Request Parameter
   * @returns {*}
   */
  updated(projectId, businessModelCanvasRequest) {
    const payload =
      businessModelCanvasRequest.name === 'BusinessModelCanvasRequest'
        ? JSON.stringify(businessModelCanvasRequest) : businessModelCanvasRequest;
    console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
    return Repository.post(`${resource}/${projectId}/business_model_canvas`, payload)
  },

}
