/**
 * @fileoverview User WebAPI の Repository です。
 * User に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'
import { encrypt } from "../../utils/Encryption";

const resource = '/users'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
	setup(Repository);
	console.log('Setup Interceptor', Repository);
}

export default {

	/**
	 * User の取得
	 *
	 * @param params Get Parameter
	 * @returns {*}
	 */
	getUserList(params) {
		console.log('getUserList', Repository.get, 'Parameters:', params)
		return Repository.get(`${resource}`, { params: params } )
	},

	update(payload) {
		if (payload.hashString) {
			// パスワードを暗号化します
			const encrypted = encrypt(encrypt(payload.hashString).encryptedData).encryptedData;
			payload.hashString = encrypted;
		}

		if (payload.hashString2) {
			// パスワードを暗号化します
			const encrypted = encrypt(encrypt(payload.hashString2).encryptedData).encryptedData;
			payload.hashString2 = encrypted;
		}

		return Repository.post(`${resource}`, payload );
	},

	delete(id) {
		return Repository.delete(`${resource}/${id}`);
	}
}
