import _ from 'lodash';

import {
  userInfo_test,
  project_test,
  brand_test,
  swot_test,
  three_cs_test,
  stp_test,
  fiveForces_test,
  pest_test,
  user_test,
  design_test,
  plan_test
} from './testData.js'

import {templates as customerJourneyMap_template} from '../components/atomic/organisms/CustomerJourneyMap_template'
import MasterData from '../utils/MasterData';

//region Import Repository
import {RepositoryFactory} from './StoreRepositoryFactory'

console.log('repository factory:', RepositoryFactory)

const LoginRepository = RepositoryFactory.get('login')
const DepartmentRepository = RepositoryFactory.get('departments');

const ProjectRepository = RepositoryFactory.get('project')
const BrandInfoRepository = RepositoryFactory.get('brandInfo')
const BpServiceRepository = RepositoryFactory.get('bpService')
const TargetValuesRepository = RepositoryFactory.get('targetValues')
const SwotAnalysisRepository = RepositoryFactory.get('swotAnalysis')
const ThreeCsAnalysisRepository = RepositoryFactory.get('threeCsAnalysis')
const StpAnalysisRepository = RepositoryFactory.get('stpAnalysis')
const FiveForcesAnalysisRepository = RepositoryFactory.get('fiveForcesAnalysis')
const PestAnalysisRepository = RepositoryFactory.get('pestAnalysis')
const FourPsFourCsAnalysisRepository = RepositoryFactory.get('fourPsFourCsAnalysis')
const FourPsComparisonAnalysisRepository = RepositoryFactory.get('fourPsComparisonAnalysis')
const BusinessModelCanvasRepository = RepositoryFactory.get('businessModelCanvas')
const UserinsightInfoRepository = RepositoryFactory.get('userinsightInfo')
const DesignInfoRepository = RepositoryFactory.get('designInfo')
const PlanningInfoRepository = RepositoryFactory.get('planningInfo')
const HowNiceRepository = RepositoryFactory.get('howNice')
const ImagesRepository = RepositoryFactory.get('images')
const SectionRepository = RepositoryFactory.get('section');
const SelectedSectionRepository = RepositoryFactory.get('selectedSection');
const SystemSettingRepository = RepositoryFactory.get('systemSetting');



//endregion
//region Local base execute mode
console.log('NODE_ENV', process.env.NODE_ENV)
const isLocalmode = process.env.NODE_ENV === 'local'
//endregion

export default {
  /* eslint-disable no-unused-vars */

  /**
   * 1-1 ログインAPI.
   *   ログイン可能か否かをチェックする。
   *
   * @param commit
   * @param eMail ログインID(eMail Address)
   * @param password パスワード
   * @return {Promise<void>}
   */
  async canLogin({commit}, {eMail: eMail, password: password}) {
    if (isLocalmode) {
      commit('userInfo', userInfo_test)
      commit('accessToken', 'abc')
      return;
    }

    commit('userInfo', null)
    commit('accessToken', null)

    const response = await LoginRepository.canLogin({
      eMail: eMail,
      password: password
    })
    if (response.data.result && response.data.resultCode === '0000') {
      console.log('LoginRepository.resuponse [Headers]', response.headers, '\n[Data]', response.data)
      const userInfo = JSON.parse(JSON.stringify(response.data.data))
      commit('userInfo', userInfo)
      commit('accessToken', localStorage.token)
    } else {
      throw new Error('ログインに失敗しました')
    }
  },

  async getDepartments({commit}) {
    const responseD = await DepartmentRepository.getDepartmentList({});
    if (responseD.data.result && responseD.data.resultCode === '0000') {
      commit( 'departmentsList', responseD.data.data.departments);
    } else {
      // this._sendError( responseD.data.message );
    }
  },

  async reflectSelectorItem() {
    const response = await SystemSettingRepository.getSelectorItem();
    if (response.data.result && response.data.resultCode === '0000') {
      const data = response.data.data;

      if(data.selectorItem) {
        const selectorItem = data.selectorItem;
        Object.keys(MasterData).forEach(k => {
          if(selectorItem[k]) {
            MasterData[k] = JSON.parse(selectorItem[k]);
          }
        });
      }
    } else {
      // this._sendError( responseD.data.message );
    }
  },

  async saveSelectorItem({commit}, {itemId, values}) {
    const response = await SystemSettingRepository.updateSelectorItem(
      itemId, JSON.stringify(values), this.getters.userInfo.userId
    );

    if (response.data.result && response.data.resultCode === '0000') {
      commit('setToast', {msg: '保存しました', color: 'green'});
      MasterData[itemId] = values;
    } else {
      // this._sendError(response.data.message)
    }
  },

  // 2-1 お知らせ(一覧) API. 【No longer needed】
  // 2-2 お知らせ(detail) API.【No longer needed】

  // 3-1 プロジェクト(一覧) API.
  // 3-2 プロジェクト(create) API.
  // 3-3 プロジェクト(update) API.

  // 3-4 プロジェクト(detail) API.
  async getProject({commit}, projectId) {
    let project;
    if (isLocalmode) {
      commit('setToast', {msg: 'getProject::testData.js から値を設定しました 😷', color: 'green'})
      project = JSON.parse(JSON.stringify(project_test))
    } else {
      console.log('getProject:API Call')
      const response = await ProjectRepository.getProject(projectId)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('getProject response:', response.data.project)
        project = response.data.data.project
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('project', project)

    if (isLocalmode) {
      commit('howNiceCount', 10)
      commit('userHowNiceFlg', false)
    } else {
      const response = await HowNiceRepository.getHowNice(projectId, this.getters.userInfo.userId)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('howNiceCount', response.data.data.projectHowNiceCount)
        commit('userHowNiceFlg', response.data.data.action == 0)
      } else {
        // this._sendError(response.data.message)
      }
    }
  },

  // 4-1 いいね API.
  // 5-1 ユーザ（一覧）API.

  // 6-1 画像アップロード API. 【Implement individually】
  // 6-2 画像アップロード削除 API. 【Implement individually】

  /**
   // 7-1 BP情報(fetch) API.
    *
    * @param commit
    * @return {Promise<void>}
    */
  async fetchBrand({commit}) {
    let brand;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchBrand::testData.js から値を設定しました 😷', color: 'green'})
      brand = JSON.parse(JSON.stringify(brand_test))
    } else {
      console.log('fetchBrand:API Call')
      const response = await BrandInfoRepository.fetchedBrandInfo(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchBrand response:', response.data.data)
        brand = JSON.parse(JSON.stringify(response.data.data))

        // // 正規雇用
        // var _regularEmployment =
        //   {
        //     organizationNum1: null,
        //     organizationNum2: null,
        //     organizationNum3: null,
        //     organizationNum4: null,
        //     organizationNum5: null,
        //   }
        // if (brand.profile.regularEmployment) {
        //   _.each( brand.profile.regularEmployment, function( item, i ){
        //     eval("_regularEmployment.organizationNum" + (i+1) + " = Number(brand.profile.regularEmployment[i])")
        //   });
        // }
        // brand.profile.regularEmployment = _regularEmployment

        // // 非正規雇用
        // var _nonRegularEmployment =
        //   {
        //     organizationNum1: null,
        //     organizationNum2: null,
        //     organizationNum3: null,
        //     organizationNum4: null,
        //     organizationNum5: null,
        //   }
        // if (brand.profile.nonRegularEmployment) {
        //   _.each( brand.profile.nonRegularEmployment, function( item, i ){
        //     eval("_nonRegularEmployment.organizationNum" + (i+1) + " = Number(brand.profile.nonRegularEmployment[i])")
        //   });
        // }
        // brand.profile.nonRegularEmployment = _nonRegularEmployment
      } else {
        // this._sendError(response.data.message)
      }
    }
    commit('brand', brand)
    commit('initBrand', true)
  },

  // 7-2 BP情報(update) API.
  async updateBrand({commit}, requestBody) {
    let brand;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateBrand::testData.js から値を設定しました 😷', color: 'green'})
      brand = JSON.parse(JSON.stringify(brand_test))
    } else {
      if (requestBody && requestBody.name === 'BrandInfoUpdateRequest') {
      } else {
        requestBody = JSON.parse(JSON.stringify(requestBody || this.getters.brand))
        requestBody.userId = this.getters.userInfo.userId
      }
      // // 正規雇用
      // const regularEmployment_bk = requestBody.profile.regularEmployment
      // requestBody.profile.regularEmployment =
      //   [
      //     regularEmployment_bk.organizationNum1 || null,
      //     regularEmployment_bk.organizationNum2 || null,
      //     regularEmployment_bk.organizationNum3 || null,
      //     regularEmployment_bk.organizationNum4 || null,
      //     regularEmployment_bk.organizationNum5 || null,
      //   ]
      // // 非正規雇用
      // const nonRegularEmployment_bk = requestBody.profile.nonRegularEmployment
      // requestBody.profile.nonRegularEmployment =
      //   [
      //     nonRegularEmployment_bk.organizationNum1 || null,
      //     nonRegularEmployment_bk.organizationNum2 || null,
      //     nonRegularEmployment_bk.organizationNum3 || null,
      //     nonRegularEmployment_bk.organizationNum4 || null,
      //     nonRegularEmployment_bk.organizationNum5 || null,
      //   ]

      const response = await BrandInfoRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateBrand response:', response.data.data)
        brand = JSON.parse(JSON.stringify(response.data.data))

        // // 正規雇用
        // var _regularEmployment =
        //   {
        //     organizationNum1: null,
        //     organizationNum2: null,
        //     organizationNum3: null,
        //     organizationNum4: null,
        //     organizationNum5: null,
        //   }
        // if (brand.profile.regularEmployment) {
        //   _.each( brand.profile.regularEmployment, function( item, i ){
        //     eval("_regularEmployment.organizationNum" + (i+1) + " = Number(brand.profile.regularEmployment[i])")
        //   });
        // }
        // brand.profile.regularEmployment = _regularEmployment

        // // 非正規雇用
        // var _nonRegularEmployment =
        //   {
        //     organizationNum1: null,
        //     organizationNum2: null,
        //     organizationNum3: null,
        //     organizationNum4: null,
        //     organizationNum5: null,
        //   }
        // if (brand.profile.nonRegularEmployment) {
        //   _.each( brand.profile.nonRegularEmployment, function( item, i ){
        //     eval("_nonRegularEmployment.organizationNum" + (i+1) + " = Number(brand.profile.nonRegularEmployment[i])")
        //   });
        // }
        // brand.profile.nonRegularEmployment = _nonRegularEmployment
      } else {
        // this._sendError(response.data.message)
      }
    }

    // commit('brand', brand)

    console.log('updateBrand')
    console.log(brand)
  },

  // 25-1 目標数値(fetch) API.
  async fetchTargetValues({commit}) {
    let targetValuesData;
    if (isLocalmode) {
    } else {
      console.log('fetchTargetValues:API Call')
      const response = await TargetValuesRepository.fetchedTargetValues(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchTargetValues response:', response.data.data)
        targetValuesData = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('targetValuesData', targetValuesData)
  },

  // 25-2 目標数値(update) API.
  async updateTargetValues({commit}, requestBody) {
    let targetValuesData;
    if (isLocalmode) {
    } else {
      if (requestBody && requestBody.name === 'targetValuesUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.targetValuesData
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await TargetValuesRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateTargetValues response:', response.data.data)
        targetValuesData = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }

    console.log('updateTargetValues')
    console.log(targetValuesData)
  },

  // 8-1 SWOT分析(fetch) API.
  async fetchSwot({commit}) {
    let swotData;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchSwot::testData.js から値を設定しました 😷', color: 'green'})
      swotData = JSON.parse(JSON.stringify(swot_test))
    } else {
      console.log('fetchBrand:API Call')
      const response = await SwotAnalysisRepository.fetchedSwotAnalysis(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchSwot response:', response.data.data)
        swotData = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('swotData', swotData)
    commit('initSwot', true)
  },

  // 8-2 SWOT分析(update) API.
  async updateSwot({commit}, requestBody) {
    let swotData;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateBrand::testData.js から値を設定しました 😷', color: 'green'})
      swotData = JSON.parse(JSON.stringify(swot_test))
    } else {
      if (requestBody && requestBody.name === 'SwotAnalysisRequest') {
      } else {
        requestBody = requestBody || this.getters.swotData
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await SwotAnalysisRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateSwot response:', response.data.data)
        swotData = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }

    // commit('swotData', swotData)

    console.log('updateSwot')
    console.log(swotData)
  },

  // 9-1 3C分析(fetch) API.
  async fetchThreeCs({commit}) {
    let threeCsData;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchThreeCs::testData.js から値を設定しました 😷', color: 'green'})
      threeCsData = JSON.parse(JSON.stringify(three_cs_test))
    } else {
      console.log('fetchThreeCs:API Call')
      const response = await ThreeCsAnalysisRepository.fetchedThreeCsAnalysis(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchThreeCs response:', response.data.data)
        threeCsData = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('threeCsData', threeCsData)
    commit('initThreeCs', true)
  },

  // 9-2 3C分析(update) API.
  async updateThreeCs({commit}, requestBody) {
    let threeCsData;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateThreeCs::testData.js から値を設定しました 😷', color: 'green'})
      threeCsData = JSON.parse(JSON.stringify(three_cs_test))
    } else {
      if (requestBody && requestBody.name === 'ThreeCsAnalysisUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.threeCsData
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await ThreeCsAnalysisRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateSwot response:', response.data.data)
        threeCsData = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }

    // commit('threeCsData', threeCsData)

    console.log('updateThreeCs')
    console.log(threeCsData)
  },

  // 10-1 STP分析(fetch) API.
  async fetchStp({commit}) {
    let stpData;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchStp::testData.js から値を設定しました 😷', color: 'green'})
      stpData = JSON.parse(JSON.stringify(stp_test))
    } else {
      console.log('fetchStp:API Call')
      const response = await StpAnalysisRepository.fetchedStpAnalysis(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchStp response:', response.data.data)
        stpData = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('stpData', stpData)
    commit('initStp', true)
  },

  // 10-2 STP分析(update) API.
  async updateStp({commit}, requestBody) {
    const projectId = this.getters.project.id;
    let stpData;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateStp::testData.js から値を設定しました 😷', color: 'green'})
      stpData = JSON.parse(JSON.stringify(stp_test))
    } else {
      if (requestBody && requestBody.name === 'stpAnalysisUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.stpData
        requestBody.userId = this.getters.userInfo.userId
      }

      if(requestBody.stp.stpPositioningmap) {
        const positioningmap = JSON.parse(requestBody.stp.stpPositioningmap);
        if(positioningmap.image) {
          const imageData = positioningmap.image.replace(/^data:\w+\/\w+;base64,/, '');
          
          const response = await ImagesRepository.uploadStp(new File([Buffer.from(imageData, "base64")], `${projectId}.png`), projectId, null)

          delete positioningmap.image;
          requestBody.stp.stpPositioningmap = JSON.stringify(positioningmap)
        }
      }

      if(requestBody.stp.positioning && requestBody.stp.positioning.length > 0) {
        for (let i = 0; i < requestBody.stp.positioning.length; i++) {
          const item = requestBody.stp.positioning[i];

          if (item.positioningPositioningmap) {
            const positioningmap = JSON.parse(item.positioningPositioningmap);
            if(positioningmap.image) {
              const imageData = positioningmap.image.replace(/^data:\w+\/\w+;base64,/, '');
              
              const response = await ImagesRepository.uploadStp(new File([Buffer.from(imageData, "base64")], `${projectId}_${item.seq}.png`), projectId, item.seq);
    
              delete positioningmap.image;
              item.positioningPositioningmap = JSON.stringify(positioningmap)
            }
          }
        }
      }

      const response = await StpAnalysisRepository.updated(projectId, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateStp response:', response.data.data)
        stpData = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    // commit('stpData', stpData)

    console.log('updateStp')
    console.log(stpData)
  },

  // 18-1 5force分析(fetch) API.
  async fetchFiveForces({commit}) {
    let fiveForcesData;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchFiveForces::testData.js から値を設定しました 😷', color: 'green'})
      fiveForcesData = JSON.parse(JSON.stringify(fiveForces_test))
    } else {
      console.log('fetchFiveForces:API Call')
      const response = await FiveForcesAnalysisRepository.fetchedFiveForcesAnalysis(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchFiveForces response:', response.data.data)
        fiveForcesData = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('fiveForcesData', fiveForcesData)
    commit('initFiveForces', true)
  },

  // 18-2 5force分析(update) API.
  async updateFiveForces({commit}, requestBody) {
    let fiveForcesData;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateFiveForces::testData.js から値を設定しました 😷', color: 'green'})
      fiveForcesData = JSON.parse(JSON.stringify(fiveForces_test))
    } else {
      if (requestBody && requestBody.name === 'fiveForcesAnalysisUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.fiveForcesData
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await FiveForcesAnalysisRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateFiveForces response:', response.data.data)
        fiveForcesData = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    // commit('fiveForcesData', fiveForcesData)

    console.log('updateFiveForces')
    console.log(fiveForcesData)
  },

  // 19-1 PEST分析(fetch) API.
  async fetchPest({commit}) {
    let pestData;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchPest::testData.js から値を設定しました 😷', color: 'green'})
      pestData = JSON.parse(JSON.stringify(pest_test))
    } else {
      console.log('fetchPest:API Call')
      const response = await PestAnalysisRepository.fetchedPestAnalysis(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchPest response:', response.data.data)
        pestData = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('pestData', pestData)
    commit('initPest', true)
  },

  // 19-2 PEST分析(update) API.
  async updatePest({commit}, requestBody) {
    let pestData;
    if (isLocalmode) {
      commit('setToast', {msg: 'updatePest::testData.js から値を設定しました 😷', color: 'green'})
      pestData = JSON.parse(JSON.stringify(pest_test))
    } else {
      if (requestBody && requestBody.name === 'pestAnalysisUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.pestData
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await PestAnalysisRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updatePest response:', response.data.data)
        pestData = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    // commit('pestData', pestData)

    console.log('updatePest')
    console.log(pestData)
  },

  // 20-1 4P4C分析(fetch) API.
  async fetchFourPsFourCs({commit}) {
    let fourPsFourCsData;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchFourPsFourCs::testData.js から値を設定しました 😷', color: 'green'})
      fourPsFourCsData = JSON.parse(JSON.stringify(fourPsFourCs_test))
    } else {
      console.log('fetchFourPsFourCs:API Call')
      const response = await FourPsFourCsAnalysisRepository.fetchedFourPsFourCsAnalysis(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchFourPsFourCs response:', response.data.data)
        fourPsFourCsData = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('fourPsFourCsData', fourPsFourCsData)
    commit('initFourPsFourCs', true)
  },

  // 20-2 4P4C分析(update) API.
  async updateFourPsFourCs({commit}, requestBody) {
    let fourPsFourCsData;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateFourPsFourCs::testData.js から値を設定しました 😷', color: 'green'})
      fourPsFourCsData = JSON.parse(JSON.stringify(fourPsFourCs_test))
    } else {
      if (requestBody && requestBody.name === 'fourPsFourCsAnalysisUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.fourPsFourCsData
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await FourPsFourCsAnalysisRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateFourPsFourCs response:', response.data.data)
        fourPsFourCsData = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    // commit('fourPsFourCsData', fourPsFourCsData)

    console.log('updateFourPsFourCs')
    console.log(fourPsFourCsData)
  },

  // 21-1 4P競合比較分析(fetch) API.
  async fetchFourPsComparison({commit}) {
    let fourPsComparisonData;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchFourPsComparison::testData.js から値を設定しました 😷', color: 'green'})
      fourPsComparisonData = JSON.parse(JSON.stringify(fourPsComparison_test))
    } else {
      console.log('fetchFourPsComparison:API Call')
      const response = await FourPsComparisonAnalysisRepository.fetchedFourPsComparisonAnalysis(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchFourPsComparison response:', response.data.data)
        fourPsComparisonData = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('fourPsComparisonData', fourPsComparisonData)
    commit('initFourPsComparison', true)
  },

  // 21-2 4P競合比較分析(update) API.
  async updateFourPsComparison({commit}, requestBody) {
    let fourPsComparisonData;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateFourPsComparison::testData.js から値を設定しました 😷', color: 'green'})
      fourPsComparisonData = JSON.parse(JSON.stringify(fourPsComparison_test))
    } else {
      if (requestBody && requestBody.name === 'fourPsComparisonAnalysisUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.fourPsComparisonData
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await FourPsComparisonAnalysisRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateFourPsComparison response:', response.data.data)
        fourPsComparisonData = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    // commit('fourPsComparisonData', fourPsComparisonData)

    console.log('updateFourPsComparison')
    console.log(fourPsComparisonData)
  },

  // 22-1 ビジネスモデルキャンバス分析(fetch) API.
  async fetchBusinessModelCanvas({commit}) {
    let businessModelCanvasData;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchBusinessModelCanvas::testData.js から値を設定しました 😷', color: 'green'})
      businessModelCanvasData = JSON.parse(JSON.stringify(businessModelCanvas_test))
    } else {
      console.log('fetchBusinessModelCanvas:API Call')
      const response = await BusinessModelCanvasRepository.fetchedBusinessModelCanvas(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchBusinessModelCanvas response:', response.data.data)
        businessModelCanvasData = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('businessModelCanvasData', businessModelCanvasData)
    commit('initBusinessModelCanvas', true)
  },

  // 22-2 ビジネスモデルキャンバス分析(update) API.
  async updateBusinessModelCanvas({commit}, requestBody) {
    let businessModelCanvasData;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateBusinessModelCanvas::testData.js から値を設定しました 😷', color: 'green'})
      businessModelCanvasData = JSON.parse(JSON.stringify(businessModelCanvas_test))
    } else {
      if (requestBody && requestBody.name === 'businessModelCanvasUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.businessModelCanvasData
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await BusinessModelCanvasRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateBusinessModelCanvas response:', response.data.data)
        businessModelCanvasData = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    // commit('businessModelCanvasData', businessModelCanvasData)

    console.log('updateBusinessModelCanvas')
    console.log(businessModelCanvasData)
  },

  // 11-1 ユーザ理解(fetch) API.
  async fetchUser({commit}) {
    let user;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchUser::testData.js から値を設定しました 😷', color: 'green'})
      user = JSON.parse(JSON.stringify(user_test))
    } else {
      console.log('fetchUser:API Call')
      const response = await UserinsightInfoRepository.fetchedUserinsightInfo(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchUser response:', response.data.data)
        user = response.data.data

        // for (let persona of user.persona.data) {
        //   if(!persona.customerJourneyData) {
        //     persona.customerJourneyData = JSON.parse(JSON.stringify(customerJourneyMap_template[0]))
        //   }
        // }

      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('user', user)
    commit('initUser', true)
  },

  // 11-2 ユーザ理解(update) API.
  async updateUser({commit}, requestBody) {
    let user;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateUser::testData.js から値を設定しました 😷', color: 'green'})
      user = JSON.parse(JSON.stringify(user_test))
    } else {
      if (requestBody && requestBody.name === 'userinsightInfoUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.user
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await UserinsightInfoRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateUser response:', response.data.data)
        user = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    // commit('user', user)

    console.log('updateUser')
    console.log(user)
  },

  // 12-1 デザイン要件(fetch) API.
  async fetchDesign({commit}) {
    let design;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchDesign::testData.js から値を設定しました 😷', color: 'green'})
      design = JSON.parse(JSON.stringify(design_test))
    } else {
      console.log('fetchDesign:API Call')
      const response = await DesignInfoRepository.fetchedDesignInfo(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchDesign response:', response.data.data)
        design = response.data.data

        if(!design.design.referencesite.data || !design.design.referencesite.data.length) {
          design.design.referencesite.data = []

          for (let i; i < 3; i++) {
            design.design.referencesite.data.push({
                siteName: null,
                siteUrl: null,
                siteImageUrl: null,
                referencePoint: null,
                seq: i+1,
            })
          }
        }
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('design', design)
    commit('initDesign', true)
  },

  // 12-2 デザイン要件(update) API.
  async updateDesign({commit}, requestBody) {
    let design;
    if (isLocalmode) {
      commit('setToast', {msg: 'updateDesign::testData.js から値を設定しました 😷', color: 'green'})
      design = JSON.parse(JSON.stringify(design_test))
    } else {
      if (requestBody && requestBody.name === 'DesignInfoUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.design
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await DesignInfoRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updateDesign response:', response.data.data)
        design = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    // commit('design', design)

    console.log('updateDesign')
    console.log(design)
  },

  // 13-1 Salesforce連携情報取得API.
  // 14-1 OGP情報取得API.

  // 15-1 プランニング(fetch)API.
  async fetchPlan({commit}) {
    let plan;
    if (isLocalmode) {
      commit('setToast', {msg: 'fetchPlan::testData.js から値を設定しました 😷', color: 'green'})
      plan = JSON.parse(JSON.stringify(plan_test))
    } else {
      console.log('fetchPlan:API Call')
      const response = await PlanningInfoRepository.fetchedBrandInfo(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchPlan response:', response.data.data)
        plan = response.data.data
      } else {
        // this._sendError(response.data.message)
      }
    }

    commit('plan', plan)
    commit('initPlan', true)
  },

  // 15-2 プランニング(update)API.
  async updatePlan({commit}, requestBody) {
    let plan;
    if (isLocalmode) {
      commit('setToast', {msg: 'updatePlan::testData.js から値を設定しました 😷', color: 'green'})
      plan = JSON.parse(JSON.stringify(plan_test))
    } else {
      if (requestBody && requestBody.name === 'DesignInfoUpdateRequest') {
      } else {
        requestBody = requestBody || this.getters.design
        requestBody.userId = this.getters.userInfo.userId
      }

      const response = await PlanningInfoRepository.updated(this.getters.project.id, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('setToast', {msg: '保存しました', color: 'green'})
        console.log('updatePlan response:', response.data.data)
        plan = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    // commit('plan', plan)

    console.log('updatePlan')
    console.log(plan)
  },

  // 16-1 部署マスタ(一覧) API.

  /* eslint-enable */

  /**
   * Toast Message データを設定する.
   *
   * @param commit
   * @param toast トーストメッセージ
   */
  setToastMessage({commit}, toast) {
    toast.color = toast.color || 'green'
    toast.timeout = toast.timeout || 2000
    commit('setToast', toast)
  },

  // 23-1 セクション(一覧)API.
  async getAllSection({commit}, params) {
    let sections;
    if (isLocalmode) {
    } else {
      const response = await SectionRepository.getAllSection(params)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('getAllSection response:', response.data.data)
        sections = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    console.log('sections', sections)
    commit('sections', sections)
  },

  // 24-1 選択セクション(登録一覧)API.
  async getRegisteredSections({commit}, id) {
    let registeredSections;
    id = id ?? this.getters.project.id;
    if (isLocalmode) {
    } else {
      console.log('getRegisteredSections:API Call')

      const response = await SelectedSectionRepository.getRegisteredSections(id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('getRegisteredSections response:', response.data.data)
        registeredSections = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    console.log('registeredSections', registeredSections)
    commit('registeredSections', registeredSections)
  },

  // 24-2 選択セクション(fetch)API.
  async fetchSelectedSection({commit}, sectionId) {
    let registeredSection;
    if (isLocalmode) {
    } else {
      console.log('fetchSelectedSection:API Call')
      let pathParam = { projectId: this.getters.project.id, sectionId: sectionId}

      const response = await SelectedSectionRepository.fetchSelectedSection(pathParam)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('fetchSelectedSection response:', response.data.data)
        registeredSection = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    console.log('registeredSection', registeredSection)
    commit('registeredSection', registeredSection)
  },

  // 24-3 選択セクション(create)API.
  async createSelectedSection({commit}, body) {
    let registeredSection;
    if (isLocalmode) {
    } else {
      console.log('createSelectedSection:API Call')
      let pathParam = { projectId: this.getters.project.id, sectionId: body.sectionId}
      let requestBody = { userId: body.userId }

      const response = await SelectedSectionRepository.createSelectedSection(pathParam, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('createSelectedSection response:', response.data.data)
        registeredSection = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    console.log('registeredSection', registeredSection)
  },

  // 24-4 選択セクション(update)API.
  async updateSelectedSection({commit}, body) {
    let registeredSection;
    if (isLocalmode) {
    } else {
      console.log('updateSelectedSection:API Call')
      let pathParam = { projectId: this.getters.project.id, sectionId: body.sectionId }
      let requestBody = { inputStatus: body.inputStatus, userId: body.userId }

      const response = await SelectedSectionRepository.updateSelectedSection(pathParam, requestBody)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('updateSelectedSection response:', response.data.data)
        registeredSection = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    console.log('registeredSection', registeredSection)
  },

  // 24-5 選択セクション(delete)API.
  async deleteSelectedSection({commit}, sectionId) {
    let registeredSection;
    if (isLocalmode) {
    } else {
      console.log('deleteSelectedSection:API Call')
      let pathParam = { projectId: this.getters.project.id, sectionId: sectionId }

      const response = await SelectedSectionRepository.deleteSelectedSection(pathParam)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('deleteSelectedSection response:', response.data.data)
        registeredSection = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    console.log('registeredSection', registeredSection)
  },

  /**
   * いいね切替.
   *
   */
   async howNice({commit}) {
    if (isLocalmode) {
      commit('howNiceCount', this.getters.howNiceCount + (this.getters.userHowNiceFlg ? -1 : 1))
      commit('userHowNiceFlg', !this.getters.userHowNiceFlg)
    } else {
      const response = await HowNiceRepository.howNice(this.getters.project.id, this.getters.userInfo.userId)
      if (response.data.result && response.data.resultCode === '0000') {
        commit('howNiceCount', response.data.data.projectHowNiceCount)
        commit('userHowNiceFlg', response.data.data.action == 1)
      } else {
        // this._sendError(response.data.message)
      }

    }

   },

  /**
   * BPサービス情報(fetch)API
   */
  async fetchService({commit}) {
    let service = null;
    if (isLocalmode) {
    } else {
      console.log('bpService:API Call')

      const response = await BpServiceRepository.fetched(this.getters.project.id)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('bpService response:', response.data.data)
        service = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    commit('bpService', service);
  },

  /**
   * BPサービス情報(update)API
   * @param {*} body 更新データ 
   */
  async updateService({commit}, body) {
    let service = null;
    if (isLocalmode) {
    } else {
      console.log('bpService:API Call')
      
      // ユーザー情報を付加
      body.userId = this.getters.userInfo.userId;

      const response = await BpServiceRepository.updated(this.getters.project.id, body)
      if (response.data.result && response.data.resultCode === '0000') {
        console.log('bpService response:', response.data.data)
        service = JSON.parse(JSON.stringify(response.data.data))
      } else {
        // this._sendError(response.data.message)
      }
    }
    commit('bpService', service);
  },

}
