/**
 * @fileoverview いいね WebAPI の Repository です。
 * いいね に関するCRUD操作を定義します。
 */
 import Repository  from '../BaseRepository'
 import setup       from '../interceptors/Interceptors'
 
 const resource = '/projects'
 
 const interceptorManager = Repository.interceptors.request
 const id = interceptorManager.handlers.length
 if (!id || id === 0) {
     setup(Repository);
     console.log('Setup Interceptor', Repository);
 }
 
 export default {
 
     /**
      * いいね 切替
      *
      * @param params Get Parameter
      * @returns {*}
      */
     howNice(projectId, userId) {
        return Repository.get(`${resource}/${projectId}/how_nice/${userId}`)
     },
 
     /**
      * いいね 情報の取得
      *
      * @param params Get Parameter
      * @returns {*}
      */
      getHowNice(projectId, userId) {
        return Repository.get(`${resource}/${projectId}/how_nice/${userId}/now`)
    },
}
 