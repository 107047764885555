/**
 * @fileoverview 振り返り情報 WebAPI の Repository です。
 * 振り返り情報 に関するCRUD操作を定義します。
 */
 import Repository from '../BaseRepository'
 import setup from '../interceptors/Interceptors'
 
 const resource = '/projects'
 
 const interceptorManager = Repository.interceptors.request
 const id = interceptorManager.handlers.length
 if (!id || id === 0) {
   setup(Repository);
   console.log('Setup Interceptor', Repository);
 }
 
 export default {
 
   /**
    * 振り返り情報 の取得
    *
    * @param id Get target projectId
    * @returns {*}
    */
   fetched(id) {
     console.log('fetched', Repository.get, '\n', 'Parameters:', id)
     return Repository.get(`${resource}/${id}/evaluation`)
   },
 
   /**
    * 振り返り情報 の更新
    *
    * @param projectId Update target projectId
    * @param body Post Request Parameter
    * @returns {*}
    */
   updated(projectId, body) {
     const payload = body;
     console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
     return Repository.post(`${resource}/${projectId}/evaluation`, payload)
   },

   /**
    * 振り返りノート の更新
    *
    * @param projectId Update target projectId
    * @param body Post Request Parameter
    * @returns {*}
    */
    updatedNote(projectId, body) {
      const payload = body;
      console.log('updatedNote:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
      return Repository.post(`${resource}/${projectId}/evaluation/note`, payload)
    },
 
 }
 