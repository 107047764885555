/**
 * @fileoverview Project WebAPI の Repository です。
 * Project に関するCRUD操作を定義します。
 */
import Repository from '../BaseRepository'
import setup from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
console.log('interceptorManager(projectRepository)', interceptorManager);
const id = interceptorManager.handlers.length
console.log('interceptorManager(projectRepository:id)', id);
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor(projectRepository)', Repository);
}

export default {

  /**
   * Project の取得
   *
   * @param params Get Parameter
   * @returns {*}
   */
  getProjectList(params) {
    console.log('getProject', Repository, 'Parameters:', params)
    return Repository.get(`${resource}`, { params: params })
  },

  /**
   * Project の取得
   *
   * @param id Get target projectId
   * @returns {*}
   */
  getProject(id) {
    console.log('getProject', Repository, '\n', 'Parameters:', id)
    return Repository.get(`${resource}/${id}`)
  },

  /**
   * Project の登録
   *
   * @param payload Post Parameter
   * @returns {*}
   */
  register(payload) {
    // Repository.defaults.headers.post['Content-Type'] = 'multipart/form-data'
    console.log('register', Repository, '\n', 'Body:', payload)
    return Repository.post(`${resource}`, payload)
  },

  /**
   * Project の更新
   *
   * @param payload Post Parameter
   * @returns {*}
   */
  update( id, payload ) {
    console.log( 'update', Repository, '\n', 'Body:', payload );
    return Repository.post( `${resource}/${id}`, payload );
  },

  /**
   * Project Group の取得
   *
   * @param id Get target projectId
   * @returns {*}
   */
  getGroup(id, params) {
    console.log('getGroup', Repository, '\n', 'Parameters:', id, params);
    return Repository.get(`${resource}/${id}/group`, { params: params });
  },

  /**
   * Project の削除
   *
   * @param id Delete target projectId
   * @returns {*}
   */
  delete(id) {
    console.log('delete', Repository, '\n', 'Parameters:', id);
    return Repository.delete(`${resource}/${id}`);
  }

}
