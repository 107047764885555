/**
 * @fileoverview Section WebAPI の Repository です。
 * Section に関するCRUD操作を定義します。
 */
import Repository from '../BaseRepository'
import setup from '../interceptors/Interceptors'

const resource = '/system_setting'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor', Repository);
}

export default{

  getSelectorItem(){
    return Repository.get(`${resource}`, {params: {category: "selectorItem"}} )
  },

  updateSelectorItem(itemId, values, userId){
    const payload = {
      selectorItem: {},
      userId: userId
    };

    payload.selectorItem[itemId] = values;

    return Repository.post(`${resource}`, payload );
  },
}