/**
 * @fileoverview Section WebAPI の Repository です。
 * Section に関するCRUD操作を定義します。
 */
 import Repository from '../BaseRepository'
 import setup from '../interceptors/Interceptors'

 const interceptorManager = Repository.interceptors.request
 const id = interceptorManager.handlers.length
 if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor', Repository);
}

export default{

  /**
   * 全てのセクションを取得
   * 
   * @param {*} id Get target project industry
   * @returns 
   */
  getAllSection(params){
    console.log('getAllSection', Repository.get, '\n')
    return Repository.get(`/sections`, { params: params } )
  },

  /**
   * セクション（パーツ）の画像パスを登録する
   * 
   * @param {*} id Get target project industry
   * @returns 
   */
   saveImagePath(){
    console.log('saveImagePath', Repository.post, '\n')
    return Repository.post(`/sections/image`)
  }
}