const initItems = (items) => {
  return items.map((item) => {
    return {
      title: item?.title || null,
      journey1: item?.journey1 || null,
      journey2: item?.journey2 || null,
      journey3: item?.journey3 || null,
      journey4: item?.journey4 || null,
      journey5: item?.journey5 || null,
      journey6: item?.journey6 || null,
      journey7: item?.journey7 || null,
      journey8: item?.journey8 || null,
      journey9: item?.journey9 || null,
      journey10: item?.journey10 || null,
      motivation: item?.motivation || [0, 0, 0],
      touchpoint1: item?.touchpoint1 || [],
      touchpoint2: item?.touchpoint2 || [],
    };
  });
};

export const templates = [
  {
    name: "歯科医院用①",
    industry: "歯科",
    items: initItems([
      { title: "認知" },
      { title: "興味関心" },
      { title: "比較検討" },
      { title: "来院" },
      { title: "通院・予防・リコール" },
      { title: "ファン化" },
    ]),
    journeySortList: [
      {
        title: "悩み",
        field: "journey1",
        type: "text",
      },
      {
        title: "行動",
        field: "journey2",
        type: "text",
      },
      {
        title: "タッチポイント\n(オンライン)",
        field: "touchpoint1",
        type: "touchpoint",
      },
      {
        title: "タッチポイント\n(オフライン)",
        field: "touchpoint2",
        type: "touchpoint",
      },
      {
        title: "モチベーション",
        field: "motivation",
        type: "motivation",
      },
      {
        title: "心理",
        field: "journey3",
        type: "text",
      },
      {
        title: "ニーズ",
        field: "journey4",
        type: "text",
      },
    ],
  },
  {
    name: "展示場出展ありVer",
    industry: "建築",
    items: initItems([
      {
        title: "認知",
        journey1:
          "・どんな家に住もうかな\n・住宅ローン毎月いくらぐらい払わないといけないのか\n・どうやって工務店を選べばいいんだろう\n・とりあえずSUUMO？",
        journey2:
          "・検索サイトで「〇〇市 注文住宅」を検索\n・比較サイトや雑誌でどういうポイントで探せばいいか確認\n・先輩や親にマイホームについて相談",
        journey3:
          "まだ注文住宅のこと何もわかってない\nまずはどんな家に住みたいかイメージを持ちたい",
        touchpoint1: ["検索エンジン(エリアKW)", "ポータルサイト掲載"],
      },
      {
        title: "興味関心",
        journey1:
          "デザインも大事だけど自然派・木のぬくもりを感じられる家に住みたい\n自分たちらしいオリジナルの家を作りたい",
        journey2:
          "・検索サイトで「〇〇市 注文住宅 無垢」を検索\n・Instagramで「○○ リビング」「■■ 家」などを検索\n・住宅展示場でモデルルーム見学",
        journey3:
          "こんな雰囲気の家に住みたい\nこんなリビング・キッチンがいい！",
        journey4: "生活イメージが湧くコンテンツ\nライフスタイル訴求コンテンツ",
        touchpoint1: ["Instagram広告", "Instagram運用", "検索エンジン(指名)"],
        touchpoint2: ["住宅展示場", "モデル/ショールーム"],
      },
      {
        title: "比較検討",
        journey1:
          "どこの工務店に頼めばいいのか？\nイメージに合う家づくりができる工務店はどこ？",
        journey2:
          "・各社のホームページや資料請求で詳細比較\n・各社の特長で自分たちのイメージに近い会社はどこか",
        journey3:
          "どこの工務店にお願いすればいいか？\nできれば親身に対応してくれる心地よい距離感の担当者がいい",
        journey4: "・実績\n・価値観やブランドメッセージ",
        touchpoint1: [
          "オフィシャルサイト",
          "検索エンジン(指名)",
          "検索エンジン(エリアKW)",
        ],
        touchpoint2: ["営業資料", "パンフレット"],
      },
      {
        title: "相見積",
        journey1:
          "似たような提案内容・価格・担当者の対応だと最終判断を下すのが難しい。\n意思決定の後押しとなるような提案が欲しい",
        journey2: "各社の条件をもとに交渉",
        journey3:
          "価格はできるだけ抑えたいけど、多少予算を超えても対応がいいところ、自分たちのイメージをカタチにできる会社にお願いしたい。",
        journey4: "・保証サービス\n・契約に関連するお得情報",
        touchpoint1: ["オフィシャルサイト"],
        touchpoint2: ["見積書", "提案資料", "商談"],
      },
      {
        title: "成約",
        journey3:
          "全部自社で対応できるところが安心感！自然素材のこだわりだけじゃなく、耐震性についても納得！\nオリジナル家具制作もお願いできるので、よりイメージに合った家づくりができそう。",
        touchpoint2: ["契約手続き"],
      },
    ]),
    journeySortList: [
      { title: "ニーズ", field: "journey3", type: "text" },
      { title: "悩み", field: "journey1", type: "text" },
      { title: "行動", field: "journey2", type: "text" },
      {
        title: "タッチポイント\n(オンライン)",
        field: "touchpoint1",
        type: "touchpoint",
      },
      {
        title: "タッチポイント\n(オフライン)",
        field: "touchpoint2",
        type: "touchpoint",
      },
      { title: "訴求コンテンツ", field: "journey4", type: "text" },
      { title: "モチベーション", field: "motivation", type: "motivation" },
    ],
  },
  {
    name: "展示場出展なしVer",
    industry: "建築",
    items: initItems([
      {
        title: "認知",
        journey1:
          "まだ注文住宅のこと何もわかってない\nまずはどんな家に住みたいかイメージを持ちたい",
        journey2:
          "・どんな家に住もうかな\n・住宅ローン毎月いくらぐらい払わないといけないのか\n・どうやって工務店を選べばいいんだろう\n・とりあえずSUUMO？",
        journey3:
          "・検索サイトで「〇〇市 注文住宅」を検索\n・比較サイトや雑誌でどういうポイントで探せばいいか確認\n・先輩や親にマイホームについて相談",
        touchpoint1: ["検索エンジン(エリアKW)", "ポータルサイト掲載"],
      },
      {
        title: "興味関心",
        journey1:
          "こんな雰囲気の家に住みたい\nこんなリビング・キッチンがいい！",
        journey2:
          "デザインも大事だけど自然派・木のぬくもりを感じられる家に住みたい\n自分たちらしいオリジナルの家を作りたい",
        journey3:
          "・検索サイトで「〇〇市 注文住宅 無垢」を検索\n・Instagramで「○○ リビング」「■■■ キッチン」などを検索\n",
        journey4: "生活イメージが湧くコンテンツ\nライフスタイル訴求コンテンツ",
        touchpoint1: [
          "Instagram運用",
          "Instagram広告",
          "検索エンジン(ニーズKW)",
        ],
      },
      {
        title: "比較検討",
        journey1:
          "どこのハウスメーカーにお願いすればいいか？\nできれば親身に対応してくれる心地よい距離感の担当者がいい",
        journey2:
          "どこの工務店に頼めばいいのか？\nイメージに合う家づくりができる工務店はどこ？",
        journey3:
          "・各社のホームページや資料請求で詳細比較\n・各社の特長で自分たちのイメージに近い会社はどこか",
        journey4: "・実績\n・価値観やブランドメッセージ",
        touchpoint1: [
          "オフィシャルサイト",
          "検索エンジン(指名)",
          "SEO対策",
          "MEO対策",
        ],
        touchpoint2: ["無料相談会", "営業資料", "パンフレット", "施行事例"],
      },
      {
        title: "相見積",
        journey1:
          "価格はできるだけ抑えたいけど、多少予算を超えても対応がいいところ、自分たちのイメージをカタチにできる会社にお願いしたい。",
        journey2:
          "似たような提案内容・価格・担当者の対応だと最終判断を下すのが難しい。\n意思決定の後押しとなるような提案が欲しい",
        journey3: "各社の条件をもとに交渉\n会社＋担当者の印象など家族で相談",
        journey4: "・保証サービス\n・契約に関連するお得情報",
        touchpoint1: ["オフィシャルサイト"],
        touchpoint2: ["見積書", "設計イメージ", "商談"],
      },
      {
        title: "成約",
        journey1:
          "全部自社で対応できるところが安心感！自然素材のこだわりだけじゃなく、耐震性についても納得！\nオリジナル家具制作もお願いできるので、よりイメージに合った家づくりができそう。",
        touchpoint2: ["契約手続き"],
      },
    ]),
    journeySortList: [
      { title: "ニーズ", field: "journey1", type: "text" },
      { title: "悩み", field: "journey2", type: "text" },
      { title: "行動", field: "journey3", type: "text" },
      {
        title: "タッチポイント\n(オンライン)",
        field: "touchpoint1",
        type: "touchpoint",
      },
      {
        title: "タッチポイント\n(オフライン)",
        field: "touchpoint2",
        type: "touchpoint",
      },
      { title: "訴求コンテンツ", field: "journey4", type: "text" },
      { title: "モチベーション", field: "motivation", type: "motivation" },
    ],
  },
  {
    name: "不動産用①",
    industry: "不動産",
    items: initItems([
      {
        title: "認知",
        journey1: "相続手続きを終わらせたい",
        journey2:
          "■父が亡くなり実家の相続が発生したため、まわりの司法書士や不動産会社に相続手続きについて相談する■まわりにいなければ行政かネット検索で不動産会社を探す",
        journey3:
          "■お葬式や相続手続きで忙しく、相続物件について全く考えていない",
        journey4: "■相続手続きについて何から調べて誰に聞くべきか知りたい",
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 1, 0],
        touchpoint1: ["YouTube広告", "ディスプレイ広告"],
        touchpoint2: ["チラシ", "新聞広告", "駅看板", "電車広告", "バス広告"],
      },
      {
        title: "興味関心",
        journey1: "自分の相続物件はどのような対応をするべきか",
        journey2:
          "■相続が完了したら遺産整理の一環として実家である相続物件をどうするか家族で話し合う",
        journey3:
          "■相続完了後、半年以内にどうにかしないといけないため急いでいる",
        journey4:
          "■相続物件を売るべきか住むべきか立て替えるべきか等、どのように処理すればいいか最適な策を知りたい",
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 2, 0],
        touchpoint1: ["SEO対策", "リスティング広告"],
        touchpoint2: [],
      },
      {
        title: "比較検討",
        journey1: "どのような売却方法があるか知りたい",
        journey2:
          "■売却することが決まれば売却手法（買取、仲介）等の希望条件を決める",
        journey3: "■売却意思は固まったが、自分に合った売却方法が分からない",
        journey4: "■地元を離れているため、空き家にならないよう早く終わらせたい",
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 3, 0],
        touchpoint1: [
          "オフィシャルサイト",
          "ポータルサイト掲載",
          "Facebook運用",
          "Instagram運用",
        ],
        touchpoint2: [],
      },
      {
        title: "商談",
        journey1: "不動産会社はイマイチ信用できない",
        journey2:
          "■一括査定サイトで一斉見積り、早く連絡がきた会社から机上査定→現調",
        journey3:
          "■不動産会社により想定査定額が違うため、どれを信用したらいいか分からない■なんとなく不動産業界は怪しいと思っている",
        journey4:
          "■相続物件の土地勘や相場に詳しい、信用できそうな地元の不動産会社にお願いしたい",
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 4, 0],
        touchpoint1: [
          "Facebook運用",
          "Instagram運用",
          "MEO対策",
          "オフィシャルサイト",
        ],
        touchpoint2: ["無料相談会"],
      },
      {
        title: "成約",
        journey1: "早く高く売って欲しい",
        journey2: "■契約",
        journey3: "■早く高く売ってくれそうな不動産会社＋人や会社が信用できそう",
        journey4:
          "■できれば物件価値が下がらないうちに買取してもらうか、仲介でもいいので早く売って欲しい",
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 5, 0],
        touchpoint1: ["オフィシャルサイト"],
        touchpoint2: ["商談"],
      },
    ]),
    journeySortList: [
      { title: "悩み", field: "journey1", type: "text" },
      { title: "行動", field: "journey2", type: "text" },
      {
        title: "タッチポイント(オンライン)",
        field: "touchpoint1",
        type: "touchpoint",
      },
      {
        title: "タッチポイント(オフライン)",
        field: "touchpoint2",
        type: "touchpoint",
      },
      { title: "モチベーション", field: "motivation", type: "motivation" },
      { title: "心理", field: "journey3", type: "text" },
      { title: "ニーズ", field: "journey4", type: "text" },
    ],
  },
  {
    name: "汎用テンプレート",
    items: initItems([
      {
        title: "認知",
        journey1: "・・・",
        journey2: "※各フェーズのユーザ行動特長を記載",
        journey3:
          "※自社プロモーション活動によってユーザにどういう態度変容を期待するかを記載",
        journey4: "※各フェーズのユーザニーズを記載",
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 0, 0],
        touchpoint1: [
          "PR記事掲載",
          "ディスプレイ広告",
          "動画広告",
          "ポータルサイト掲載",
        ],
        touchpoint2: ["マス広告", "OOH"],
      },
      {
        title: "興味関心",
        journey1: "・・・",
        journey2: null,
        journey3: null,
        journey4: null,
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 0, 0],
        touchpoint1: ["SEO対策", "リスティング広告", "オフィシャルサイト"],
        touchpoint2: [],
      },
      {
        title: "比較検討",
        journey1: "・比較コンテンツ・利用者事例コンテンツ・",
        journey2: null,
        journey3: null,
        journey4: null,
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 0, 0],
        touchpoint1: ["eBook", "記事ページ", "ポータルサイト掲載"],
        touchpoint2: [],
      },
      {
        title: "購入・お申込み",
        journey1: "・・・",
        journey2: null,
        journey3: null,
        journey4: null,
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 0, 0],
        touchpoint1: ["オフィシャルサイト"],
        touchpoint2: [],
      },
      {
        title: "ファン化",
        journey1: "・・・",
        journey2: null,
        journey3: null,
        journey4: null,
        journey5: null,
        journey6: null,
        journey7: null,
        journey8: null,
        journey9: null,
        journey10: null,
        motivation: [0, 0, 0],
        touchpoint1: [],
        touchpoint2: [],
      },
    ]),
    journeySortList: [
      { title: "ユーザニーズ・悩み/課題", field: "journey4", type: "text" },
      { title: "行動", field: "journey2", type: "text" },
      {
        title: "タッチポイント(オンライン)",
        field: "touchpoint1",
        type: "touchpoint",
      },
      {
        title: "タッチポイント(オフライン)",
        field: "touchpoint2",
        type: "touchpoint",
      },
      { title: "訴求内容", field: "journey1", type: "text" },
      { title: "モチベーション", field: "motivation", type: "motivation" },
      { title: "期待する態度変容", field: "journey3", type: "text" },
    ],
  },
];
