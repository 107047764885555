/**
 * @fileoverview SelectedSection WebAPI の Repository です。
 * SelectedSection に関するCRUD操作を定義します。
 */
 import Repository from '../BaseRepository'
 import setup from '../interceptors/Interceptors'

 const resource = '/projects'

 const interceptorManager = Repository.interceptors.request
 const id = interceptorManager.handlers.length
 if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor', Repository);
}

export default{

  /**
   * 登録済みセクション一覧の取得
   * 
   * @param {*} id Get target projectid
   * @returns 
   */
  getRegisteredSections(id){
    console.log('getRegisteredSections', Repository.get, '\n', 'Parameters:', id)
    return Repository.get(`${resource}/${id}/selected_sections`)
  },

  /**
   * 登録済みセクション情報の取得
   * 
   * @param {*} pathParam
   * @returns 
   */
   fetchSelectedSection(pathParam){
    console.log('fetchSelectedSection', Repository.get, '\n', 'Parameters:', pathParam)
    return Repository.get(`${resource}/${pathParam.projectId}/selected_sections/${pathParam.sectionId}`)
  },

  /**
   * プロジェクトにセクションを登録する.
   * 
   * @param {*} pathParam
   * @param {*} requestBody 
   * @returns 
   */
  createSelectedSection(pathParam, requestBody){
    console.log('createSelectedSection', Repository.post, '\n', 'Parameters:', pathParam, requestBody)
    return Repository.post(`${resource}/${pathParam.projectId}/selected_sections/${pathParam.sectionId}`,requestBody)
  },

  /**
   * 選択セクション情報を更新する.
   * 
   * @param {*} pathParam 
   * @param {*} requestBody 
   * @returns 
   */
  updateSelectedSection(pathParam, requestBody){
    console.log('updateSelectedSection', Repository.put, '\n', 'Parameters:', pathParam, requestBody)
    return Repository.put(`${resource}/${pathParam.projectId}/selected_sections/${pathParam.sectionId}`,requestBody)
  },

  /**
   * プロジェクトからセクションを削除する.
   * 
   * @param {*} pathParam 
   * @param {*} requestBody 
   * @returns 
   */
  deleteSelectedSection(pathParam, requestBody){
    console.log('deleteSelectedSection', Repository.delete, '\n', 'Parameters:', pathParam, requestBody)
    return Repository.delete(`${resource}/${pathParam.projectId}/selected_sections/${pathParam.sectionId}`,requestBody)
  }
}