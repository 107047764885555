/**
 * @fileoverview Repository Factory です。
 * repositories に必要なリポジトリを登録していき、RepositoryFactory の get メソッドで必要なリポジトリを取得する流れです。
 */
import loginRepository                    from '@/api/repositories/loginRepository'

import projectRepository                  from '@/api/repositories/projectRepository'
import usersRepository                    from '@/api/repositories/usersRepository'
import imagesRepository                   from '@/api/repositories/imagesRepository'
import brandInfoRepository                from '@/api/repositories/brandInfoRepository'
import bpServiceRepository                from '@/api/repositories/bpServiceRepository'
import targetValuesRepository             from '@/api/repositories/targetValuesRepository'
import swotAnalysisRepository             from '@/api/repositories/swotAnalysisRepository'
import threeCsAnalysisRepository          from '@/api/repositories/threeCsAnalysisRepository'
import stpAnalysisRepository              from '@/api/repositories/stpAnalysisRepository'
import fiveForcesAnalysisRepository       from '@/api/repositories/fiveForcesAnalysisRepository'
import pestAnalysisRepository             from '@/api/repositories/pestAnalysisRepository'
import fourPsFourCsAnalysisRepository     from '@/api/repositories/fourPsFourCsAnalysisRepository'
import fourPsComparisonAnalysisRepository from '@/api/repositories/fourPsComparisonAnalysisRepository'
import businessModelCanvasRepository      from '@/api/repositories/businessModelCanvasRepository'
import userinsightInfoRepository          from '@/api/repositories/userinsightInfoRepository'
import designInfoRepository               from '@/api/repositories/designInfoRepository'
import planningInfoRepository             from '@/api/repositories/planningInfoRepository'
import salesforceRepository               from '@/api/repositories/salesforceRepository'
import ogpRepository                      from '@/api/repositories/ogpRepository'
import departmentsRepository              from "@/api/repositories/departmentsRepository";
import howNiceRepository                  from "@/api/repositories/howNiceRepository";
import overviewRepository                 from "@/api/repositories/overviewRepository";
import sectionRepository                  from "@/api/repositories/sectionRepository";
import selectedSectionRepository          from "@/api/repositories/selectedSectionRepository";
import systemSettingRepository            from "@/api/repositories/systemSettingRepository";



const repositories = {
  login                   : loginRepository,

  project                 : projectRepository,
  users                   : usersRepository,
  images                  : imagesRepository,
  brandInfo               : brandInfoRepository,
  bpService               : bpServiceRepository,
  targetValues            : targetValuesRepository,
  swotAnalysis            : swotAnalysisRepository,
  threeCsAnalysis         : threeCsAnalysisRepository,
  stpAnalysis             : stpAnalysisRepository,
  fiveForcesAnalysis      : fiveForcesAnalysisRepository,
  pestAnalysis            : pestAnalysisRepository,
  fourPsFourCsAnalysis    : fourPsFourCsAnalysisRepository,
  fourPsComparisonAnalysis: fourPsComparisonAnalysisRepository,
  businessModelCanvas     : businessModelCanvasRepository,
  userinsightInfo         : userinsightInfoRepository,
  designInfo              : designInfoRepository,
  planningInfo            : planningInfoRepository,
  salesforce              : salesforceRepository,
  ogp                     : ogpRepository,
  departments             : departmentsRepository,
  howNice                 : howNiceRepository,
  overview                : overviewRepository,
  section                 : sectionRepository,
  selectedSection         : selectedSectionRepository,
  systemSetting           : systemSettingRepository,
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
