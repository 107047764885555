// const 1040
const MAX_WIDTH = 1040;

export default {
  namespaced: true,

  state: {
    areaWidth: 0,
    contentPaddingLeft: 0,

  },
  mutations: {
    areaWidth(state, value) {
      state.areaWidth = Math.max(MAX_WIDTH, value);
      state.contentPaddingLeft = Math.max(0, (value-MAX_WIDTH)/2 );
    },
  },
  getters: {
    areaWidth: state => state.areaWidth,
    contentPaddingLeft: state => state.contentPaddingLeft,

    scrollAreaStyle(state) {
      return {
        position: 'relative',
        left: -1 * state.contentPaddingLeft + 'px',
        width: state.areaWidth + 'px',
        transition: "all 0.5s 0s ease",
      };
    },

    contentAreaStyle(state) {
      return {
        paddingLeft: state.contentPaddingLeft + 'px',
        transition: "all 0.5s 0s ease",
      };
    },
  },
}
