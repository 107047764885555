/**
 * @fileoverview STP分析 WebAPI の Repository です。
 * STP分析 に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
    setup(Repository);
    console.log('Setup Interceptor', Repository);
}

export default {

    /**
     * STP分析情報 の取得
     *
     * @param projectId Get target projectId
     * @returns {*}
     */
    fetchedStpAnalysis(projectId) {
        console.log('fetchedStpAnalysis:', Repository.get, '\nParameters:', projectId)
        return Repository.get(`${resource}/${projectId}/stp_analysis`)
    },

    /**
     * STP分析情報 の更新
     *
     * @param projectId Update target projectId
     * @param stpAnalysisUpdateRequest Post Request Parameter
     * @returns {*}
     */
    updated(projectId, stpAnalysisUpdateRequest) {
        const payload =
          stpAnalysisUpdateRequest.name === 'stpAnalysisUpdateRequest'
            ? JSON.stringify(stpAnalysisUpdateRequest) : stpAnalysisUpdateRequest;
        console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
        return Repository.post(`${resource}/${projectId}/stp_analysis`, payload)
    },

}
