/**
 * @fileoverview Project WebAPI の Repository です。
 * Project に関するCRUD操作を定義します。
 */
 import Repository from '../BaseRepository'
 
 const resource = '/projects'
 
 export default {
 
   /**
    * Project の複製
    *
    * @param id projectId
    * @param payload Post data
    * @returns {*}
    */
   copyProject(id, payload) {
     console.log('copyProject', Repository, 'Parameters:', payload)
     return Repository.post(`${resource}/${id}/copy`, payload)
   },
  }