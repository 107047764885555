<template>
  <v-app>
    <!-- <MainPage/> -->
    <!-- <GalleryPage v-if="galleryMode"/> -->
    <router-view></router-view>
    <Snackbar></Snackbar>
  </v-app>
</template>

<script>
// import GalleryPage from './views/GalleryPage.vue'
import Snackbar from './components/Snackbar.vue'

export default {
  name: 'App',
  components: {
    // GalleryPage
    Snackbar
  },

  data: () => ({
    galleryMode: true
  }),

  async created() {
    await this.$store.dispatch("reflectSelectorItem")
  }
};
</script>
