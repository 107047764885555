//  業界
const industries = [
  {
    id: 1,
    industry: "歯科"
  },
  {
    id: 2,
    industry: "歯科技工所"
  },
  {
    id: 3,
    industry: "医療"
  },
  {
    id: 4,
    industry: "不動産"
  },
  {
    id: 5,
    industry: "建築"
  },
  {
    id: 6,
    industry: "製造"
  },
  {
    id: 7,
    industry: "人材"
  },
  {
    id: 8,
    industry: "IT/情報サービス"
  },
  // {
  //   id: 2,
  //   industry: "業界その2"
  // },
  // {
  //   id: 3,
  //   industry: "業界その3"
  // }
];

//  フェーズ
const businessPhases = [
  {
    id: 1,
    businessPhase: "創業期"
  },
  {
    id: 2,
    businessPhase: "成長期"
  },
  {
    id: 3,
    businessPhase: "拡大期"
  },
  {
    id: 4,
    businessPhase: "安定期"
  },
  {
    id: 5,
    businessPhase: "衰退期"
  },
  {
    id: 6,
    businessPhase: "継承期"
  }
];

//  課題分類
const issues = [
  {
    id: 1,
    issue: "集患強化"
  },
  {
    id: 2,
    issue: "自費売上UP"
  },
  {
    id: 3,
    issue: "採用強化"
  },
  {
    id: 4,
    issue: "ブランディング"
  },
  {
    id: 5,
    issue: "集客"
  },
  {
    id: 6,
    issue: "認知拡大"
  },
  {
    id: 7,
    issue: "リード質改善"
  },
  {
    id: 8,
    issue: "CAC改善"
  },
  {
    id: 9,
    issue: "LTV改善"
  }
];

//  ステータス
const statuses = [
  {
    id: 1,
    status: "営業中"
  },
  {
    id: 2,
    status: "制作中"
  },
  {
    id: 3,
    status: "運用中"
  },
  {
    id: 4,
    status: "失注"
  },
  {
    id: 5,
    status: "解約"
  }
];

// 設計評価
const visionEvaluation = [
  {
    id: 1,
    visionEvaluation: '院長自らビジョン設計ができている'
  },
  {
    id: 2,
    visionEvaluation: '言語化はできていないがイメージはある'
  },
  {
    id: 3,
    visionEvaluation: 'イメージできていない'
  }
]

// タッチポイント「オンライン」
const onlineMeasuresDentistry = [
  {
    id: 1,
    onlineMeasures: '予約システム'
  }, {
    id: 2,
    onlineMeasures: 'オフィシャルHP'
  },
  {
    id: 3,
    onlineMeasures: 'SEO対策'
  },
  {
    id: 4,
    onlineMeasures: 'MEO対策'
  },
  {
    id: 5,
    onlineMeasures: 'Web広告'
  },
  {
    id: 6,
    onlineMeasures: '動画制作'
  },
  {
    id: 7,
    onlineMeasures: 'Youtube広告'
  },
  {
    id: 8,
    onlineMeasures: 'SNS広告'
  },
  {
    id: 9,
    onlineMeasures: '外部コンサル'
  },
  {
    id: 10,
    onlineMeasures: 'ポータルサイト掲載'
  },
  {
    id: 11,
    onlineMeasures: 'サテライトサイト'
  },
  {
    id: 12,
    onlineMeasures: '採用サイト'
  },
  {
    id: 13,
    onlineMeasures: '採用ポータルサイト'
  },
  {
    id: 14,
    onlineMeasures: 'eBook'
  },
  {
    id: 15,
    onlineMeasures: 'Web問診'
  },
  {
    id: 16,
    onlineMeasures: 'オンライン診療'
  },
  {
    id: 17,
    onlineMeasures: 'メルマガ'
  },
  {
    id: 18,
    onlineMeasures: '患者アンケート'
  },
  {
    id: 19,
    onlineMeasures: 'Google口コミ促進'
  },
  {
    id: 20,
    onlineMeasures: 'コミュニケーションツール(LINEなど)'
  }
]

// タッチポイント「オンライン」
const onlineMeasuresRealEstate = [
  {
    id: 1,
    onlineMeasures: 'オフィシャルサイト'
  }, {
    id: 2,
    onlineMeasures: 'ポータルサイト掲載'
  },
  {
    id: 3,
    onlineMeasures: 'SEO対策'
  },
  {
    id: 4,
    onlineMeasures: 'リスティング広告'
  },
  {
    id: 5,
    onlineMeasures: 'MEO対策'
  },
  {
    id: 6,
    onlineMeasures: 'Facebook運用'
  },
  {
    id: 7,
    onlineMeasures: 'Instagram運用'
  },
  {
    id: 8,
    onlineMeasures: 'Youtube広告'
  },
  {
    id: 9,
    onlineMeasures: 'Facebook広告'
  },
  {
    id: 10,
    onlineMeasures: 'Instagram広告'
  },
  {
    id: 11,
    onlineMeasures: 'ポータルサイト掲載'
  },
  {
    id: 12,
    onlineMeasures: 'サテライトサイト'
  },
  {
    id: 13,
    onlineMeasures: 'eBook'
  },
  {
    id: 14,
    onlineMeasures: 'PR記事掲載'
  },
  {
    id: 15,
    onlineMeasures: 'チャットボット'
  },
  {
    id: 16,
    onlineMeasures: 'Web接客'
  },
]

const onlineMeasuresBuilding = [
  {
    id: 1,
    onlineMeasures: 'オフィシャルサイト'
  }, {
    id: 2,
    onlineMeasures: 'ポータルサイト掲載'
  },
  {
    id: 3,
    onlineMeasures: 'SEO対策'
  },
  {
    id: 4,
    onlineMeasures: 'リスティング広告'
  },
  {
    id: 5,
    onlineMeasures: 'MEO対策'
  },
  {
    id: 6,
    onlineMeasures: 'Facebook運用'
  },
  {
    id: 7,
    onlineMeasures: 'Instagram運用'
  },
  {
    id: 8,
    onlineMeasures: 'Youtube広告'
  },
  {
    id: 9,
    onlineMeasures: 'Facebook広告'
  },
  {
    id: 10,
    onlineMeasures: 'Instagram広告'
  },
  {
    id: 11,
    onlineMeasures: 'ポータルサイト掲載'
  },
  {
    id: 12,
    onlineMeasures: 'サテライトサイト'
  },
  {
    id: 13,
    onlineMeasures: 'eBook'
  },
  {
    id: 14,
    onlineMeasures: 'PR記事掲載'
  },
  {
    id: 15,
    onlineMeasures: 'チャットボット'
  },
  {
    id: 16,
    onlineMeasures: 'Web接客'
  },
]

const onlineMeasuresOther = [
  {
    id: 1,
    onlineMeasures: 'オフィシャルサイト'
  }, {
    id: 2,
    onlineMeasures: 'ポータルサイト掲載'
  },
  {
    id: 3,
    onlineMeasures: 'SEO対策'
  },
  {
    id: 4,
    onlineMeasures: 'リスティング広告'
  },
  {
    id: 5,
    onlineMeasures: 'MEO対策'
  },
  {
    id: 6,
    onlineMeasures: 'Facebook運用'
  },
  {
    id: 7,
    onlineMeasures: 'Instagram運用'
  },
  {
    id: 8,
    onlineMeasures: 'Youtube広告'
  },
  {
    id: 9,
    onlineMeasures: 'Facebook広告'
  },
  {
    id: 10,
    onlineMeasures: 'Instagram広告'
  },
  {
    id: 11,
    onlineMeasures: 'ポータルサイト掲載'
  },
  {
    id: 12,
    onlineMeasures: 'サテライトサイト'
  },
  {
    id: 13,
    onlineMeasures: 'eBook'
  },
  {
    id: 14,
    onlineMeasures: 'PR記事掲載'
  },
  {
    id: 15,
    onlineMeasures: 'チャットボット'
  },
  {
    id: 16,
    onlineMeasures: 'Web接客'
  },
  {
    id: 17,
    onlineMeasures: 'メルマガ'
  },
]

// タッチポイント「オフライン」
const offlineMeasuresDentistry = [
  {
    id: 1,
    offlineMeasures: 'タウン誌掲載'
  },
  {
    id: 2,
    offlineMeasures: '新聞広告'
  },
  {
    id: 3,
    offlineMeasures: '駅看板'
  },
  {
    id: 4,
    offlineMeasures: '電車広告'
  },
  {
    id: 5,
    offlineMeasures: '野立て看板'
  },
  {
    id: 6,
    offlineMeasures: '電柱看板'
  },
  {
    id: 7,
    offlineMeasures: 'バス広告'
  },
  {
    id: 8,
    offlineMeasures: 'タクシー広告'
  },
  {
    id: 9,
    offlineMeasures: 'リコールハガキ'
  },
  {
    id: 10,
    offlineMeasures: '自動精算機'
  },
  {
    id: 11,
    offlineMeasures: '郵便広告'
  },
  {
    id: 12,
    offlineMeasures: 'キャラクター'
  },
  {
    id: 13,
    offlineMeasures: '外壁内装デザイン'
  },
  {
    id: 14,
    offlineMeasures: '出版'
  },
  {
    id: 15,
    offlineMeasures: '患者向け資料'
  },
  {
    id: 16,
    offlineMeasures: 'イベント企画'
  },
  {
    id: 17,
    offlineMeasures: '患者アンケート'
  },
  {
    id: 18,
    offlineMeasures: '人事制度'
  },
  {
    id: 19,
    offlineMeasures: 'スタッフマネジメント'
  },
  {
    id: 20,
    offlineMeasures: '患者マネジメント'
  },
  {
    id: 21,
    offlineMeasures: '外部コンサル'
  }
]

const offlineMeasuresRealEstate = [
  {
    id: 1,
    offlineMeasures: 'タウン誌掲載'
  },
  {
    id: 2,
    offlineMeasures: '新聞広告'
  },
  {
    id: 3,
    offlineMeasures: 'チラシ'
  },
  {
    id: 4,
    offlineMeasures: '駅看板'
  },
  {
    id: 5,
    offlineMeasures: '電車広告'
  },
  {
    id: 6,
    offlineMeasures: '野立て看板'
  },
  {
    id: 7,
    offlineMeasures: '電柱看板'
  },
  {
    id: 8,
    offlineMeasures: 'バス広告'
  },
  {
    id: 9,
    offlineMeasures: 'タクシー広告'
  },
  {
    id: 10,
    offlineMeasures: '雑誌広告'
  },
  {
    id: 11,
    offlineMeasures: '無料相談会'
  },
  {
    id: 12,
    offlineMeasures: '勉強会/セミナー'
  },
  {
    id: 13,
    offlineMeasures: 'モデル/ショールーム'
  },
]

const offlineMeasuresBuilding = [
  {
    id: 1,
    offlineMeasures: 'タウン誌掲載'
  },
  {
    id: 2,
    offlineMeasures: '新聞広告'
  },
  {
    id: 3,
    offlineMeasures: 'チラシ'
  },
  {
    id: 4,
    offlineMeasures: '駅看板'
  },
  {
    id: 5,
    offlineMeasures: '電車広告'
  },
  {
    id: 6,
    offlineMeasures: '野立て看板'
  },
  {
    id: 7,
    offlineMeasures: '電柱看板'
  },
  {
    id: 8,
    offlineMeasures: 'バス広告'
  },
  {
    id: 9,
    offlineMeasures: '雑誌広告'
  },
  {
    id: 10,
    offlineMeasures: '無料相談会'
  },
  {
    id: 11,
    offlineMeasures: '完成見学会'
  },
  {
    id: 12,
    offlineMeasures: '勉強会/セミナー'
  },
  {
    id: 13,
    offlineMeasures: 'モデル/ショールーム'
  },
  {
    id: 14,
    offlineMeasures: '住宅展示場'
  },
]

const offlineMeasuresOther = [
  {
    id: 1,
    offlineMeasures: 'タウン誌掲載'
  },
  {
    id: 2,
    offlineMeasures: '新聞広告'
  },
  {
    id: 3,
    offlineMeasures: 'チラシ'
  },
  {
    id: 4,
    offlineMeasures: '駅看板'
  },
  {
    id: 5,
    offlineMeasures: '電車広告'
  },
  {
    id: 6,
    offlineMeasures: '野立て看板'
  },
  {
    id: 7,
    offlineMeasures: '電柱看板'
  },
  {
    id: 8,
    offlineMeasures: 'バス広告'
  },
  {
    id: 9,
    offlineMeasures: 'タクシー広告'
  },
  {
    id: 10,
    offlineMeasures: '雑誌広告'
  },
  {
    id: 11,
    offlineMeasures: '無料相談会'
  },
  {
    id: 12,
    offlineMeasures: '勉強会/セミナー'
  },
]

// 戦略と施策評価
const strategicEvaluation =  [
  {
    id: 1,
    strategicEvaluation: '戦略に合わせた施策ができている'
  },
  {
    id: 2,
    strategicEvaluation: '戦略はあるが施策実行できてない'
  },
  {
    id: 3,
    strategicEvaluation: '戦略なしに施策を打っている'
  },
  {
    id: 4,
    strategicEvaluation: '戦略も施策もできていない'
  }
]

// 戦略ノートカテゴリー
const overviewCategory =  [
  {
    id: 1,
    overviewCategory: 'カテゴリ１'
  },
  {
    id: 2,
    overviewCategory: 'カテゴリ２'
  },
  {
    id: 3,
    overviewCategory: 'カテゴリ３'
  },
  {
    id: 4,
    overviewCategory: 'カテゴリ４'
  }
]

// STP差別化ポイント
const stpDifferentiation =  [
  {
    id: 1,
    stpDifferentiation: '差別化ポイント１'
  },
  {
    id: 2,
    stpDifferentiation: '差別化ポイント２'
  },
  {
    id: 3,
    stpDifferentiation: '差別化ポイント３'
  },
  {
    id: 4,
    stpDifferentiation: '差別化ポイント４'
  }
];

// snsアカウント
const snsAccount = ["Facebook", "Instagram", "Twitter", "TikTok", "LINE"];

// >>>サービス情報
//立地属性１～４
const locationCategory1 = ["住宅地", "オフィス街", "繁華街"];
const locationCategory2 = ["戸建て", "ビルテナント１F", "ビルテナント２F以上", "医療モール", "ショッピングモール",];
const locationCategory3 = ["駅近", "幹線道路", "駅近×幹線道路"];
const locationCategory4 = ["新規", "居抜き", "新規×継承", "居抜き×継承", "家族継承",];
// 業態 TODO: 仮項目
const serviceIndustries = ["業態1", "業態2", "業態3", "業態4", "業態5"];
// サービスタグ TODO: 仮項目
const serviceTags = [""];
const serviceTagsRealEstate = [""];
const serviceTagsBuilding = [
  "北欧",
  "南欧",
  "プロバンス",
  "アメリカン",
  "ブリティッシュ",
  "フレンチ",
  "イタリアン",
  "スパニッシュ",
  "アジアン",
  "和風",
  "モダン",
  "エレガンス",
  "ナチュラル",
  "クラシック",
  "カントリー",
  "カジュアル",
  "クール",
  "シンプル",
  "ミッドセンチュリー",
  "リゾート",
  "ホテル",
  "ヴィンテージ",
  "アンティーク",
  "カフェ",
  "シャビ―シック",
  "ガーリー",
];


// >>>ペルソナ
// 年齢層
const ageOption = [
  "10代",
  "20代前半",
  "20代後半",
  "30代前半",
  "30代後半",
  "40代前半",
  "40代後半",
  "50代",
  "60代以上",
];
// 年収
const incomeYearOption = [
  "100万未満",
  "100～200万未満",
  "200万～400万未満",
  "400～600万未満",
  "600～800万未満",
  "800万～1000万未満",
  "1000万以上",
];
// 交通手段
const transportationTypeOption = ["電車", "バス", "マイカー", "カーシェア"];
// 情報収集手段
const collectInfoOption = [
  "インターネット",
  "ニュースアプリ",
  "TV",
  "新聞",
  "雑誌",
  "業界誌",
  "ラジオ",
  "コミュニティ",
];
// 職業
const jobOption = [
  "会社員",
  "公務員",
  "自営業",
  "会社役員",
  "経営者",
  "自由業",
  "専業主婦/夫",
  "中学生",
  "高校生",
  "専門学校/大学生",
  "パート/アルバイト",
  "無職",
];
// よく使うSNS
const favoriteSnsOption = ["Facebook", "Instagram", "Twitter", "TikTok", "LINE"];
// 拠点数
const officeNumRangeOption = [
  "1拠点",
  "2-5拠点",
  "6-10拠点",
  "11-50拠点",
  "51-100拠点",
  "100拠点以上",
];
// 従業員数
const employeeNumRangeOption = [
  "1-5人",
  "6-10人",
  "11-20人",
  "21-50人",
  "51-100人",
  "101-200人",
  "201-300人",
  "301-500人",
  "501-1000人",
  "1000人以上",
];
// 売上高
const amountRangeOption = [
  "5千万未満",
  "5千万‐1億未満",
  "1-10億未満",
  "10-20億未満",
  "20-50億未満",
  "50-100億未満",
  "100-500億未満",
  "500-1000億未満",
  "1000億以上",
];
// 所属部署
const departmentOption = [
  "マーケティング",
  "営業",
  "開発",
  "製造",
  "サービス",
  "CRM",
  "総務",
  "人事",
  "経理",
  "情報システム",
  "広報",
  "経営企画",
];
// 役職
const jobPositionOption = [
  "社長",
  "取締役",
  "部長",
  "課長",
  "リーダー",
  "一般",
];

// デザイン要件　フォントイメージ
const designFontItems = [
  'ゴシック',
  '丸ゴシック',
  '明朝体',
  '行書体',
  'デザイン書体',
];

// デザイン要件　デザインキーワード
const designKeywordItems = [
  '男性的',
  '女性的',
  'クール',
  'ポップ',
  'シック',
  '和風',
  'さわやか',
  '清潔感',
  '楽しい',
  'にぎやか',
  '伝統的',
  'エスニック',
  '異国風',
  '真面目',
  '堅実',
  'くつろぎ',
  'のどか',
  '神秘的',
  'かわいい',
  '子供っぽい',
  '都会的',
  '事務的',
  '派手',
  '高級感',
  '元気',
  '活動的',
  'アットホーム',
  'きれい',
  '明るい',
  '華やか',
]

// 振り返り　Good要因
const evaluationGoodFactor = ["Good要因1", "Good要因2", "Good要因3"];
// 振り返り　Bad要因
const evaluationBadFactor = ["Bad要因1", "Bad要因2", "Bad要因3"];
// 振り返り　自社評価
const evaluationBtEvaluation = ["評価1", "評価2", "評価3"];
// 振り返り　クライアント評価
const evaluationClientEvaluation = ["評価1", "評価2", "評価3"];

export default {
  industries,
  businessPhases,
  issues,
  statuses,
  visionEvaluation,
  onlineMeasuresDentistry,
  onlineMeasuresRealEstate,
  onlineMeasuresBuilding,
  onlineMeasuresOther,
  offlineMeasuresDentistry,
  offlineMeasuresRealEstate,
  offlineMeasuresBuilding,
  offlineMeasuresOther,
  strategicEvaluation,
  overviewCategory,
  stpDifferentiation,
  snsAccount,
  locationCategory1,
  locationCategory2,
  locationCategory3,
  locationCategory4,
  serviceIndustries,
  serviceTags,
  serviceTagsRealEstate,
  serviceTagsBuilding,
  ageOption,
  incomeYearOption,
  transportationTypeOption,
  collectInfoOption,
  jobOption,
  jobPositionOption,
  departmentOption,
  amountRangeOption,
  employeeNumRangeOption,
  officeNumRangeOption,
  favoriteSnsOption,
  designFontItems,
  designKeywordItems,
  evaluationGoodFactor,
  evaluationBadFactor,
  evaluationBtEvaluation,
  evaluationClientEvaluation,
};
