/**
 * @fileoverview Repository Factory です.
 * repositories に必要なリポジトリを登録していき、RepositoryFactory の get メソッドで必要なリポジトリを取得する流れです.
 */
import loginRepository                    from '../api/repositories/loginRepository'

import projectRepository                  from '../api/repositories/projectRepository'
import projectCopyRepository              from '../api/repositories/projectCopyRepository'
import usersRepository                    from '../api/repositories/usersRepository'
import imagesRepository                   from '../api/repositories/imagesRepository'
import brandInfoRepository                from '../api/repositories/brandInfoRepository'
import bpServiceRepository                from '../api/repositories/bpServiceRepository'
import targetValuesRepository                from '../api/repositories/targetValuesRepository'
import swotAnalysisRepository             from '../api/repositories/swotAnalysisRepository'
import threeCsAnalysisRepository          from '../api/repositories/threeCsAnalysisRepository'
import stpAnalysisRepository              from '../api/repositories/stpAnalysisRepository'
import fiveForcesAnalysisRepository       from '../api/repositories/fiveForcesAnalysisRepository'
import pestAnalysisRepository             from '../api/repositories/pestAnalysisRepository'
import fourPsFourCsAnalysisRepository     from '../api/repositories/fourPsFourCsAnalysisRepository'
import fourPsComparisonAnalysisRepository from '../api/repositories/fourPsComparisonAnalysisRepository'
import businessModelCanvasRepository      from '../api/repositories/businessModelCanvasRepository'
import userinsightInfoRepository          from '../api/repositories/userinsightInfoRepository'
import planningInfoRepository             from '../api/repositories/planningInfoRepository'
import designInfoRepository               from '../api/repositories/designInfoRepository'
import salesforceRepository               from '../api/repositories/salesforceRepository'
import slidesRepository                   from '../api/repositories/slidesRepository'
import ogpRepository                      from '../api/repositories/ogpRepository'
import departmentsRepository              from '../api/repositories/departmentsRepository'
import howNiceRepository                  from '../api/repositories/howNiceRepository'
import overviewRepository                 from '../api/repositories/overviewRepository'
import systemSettingRepository            from '../api/repositories/systemSettingRepository'
import sectionRepository                  from '../api/repositories/sectionRepository'
import evaluationRepository               from './repositories/EvaluationRepository'



const repositories = {
  login                   : loginRepository,

  project                 : projectRepository,
  projectCopy             : projectCopyRepository,
  users                   : usersRepository,
  images                  : imagesRepository,
  brandInfo               : brandInfoRepository,
  bpService               : bpServiceRepository,
  targetValues            : targetValuesRepository,
  swotAnalysis            : swotAnalysisRepository,
  threeCsAnalysis         : threeCsAnalysisRepository,
  stpAnalysis             : stpAnalysisRepository,
  fiveForcesAnalysis      : fiveForcesAnalysisRepository,
  pestAnalysis            : pestAnalysisRepository,
  fourPsFourCsAnalysis    : fourPsFourCsAnalysisRepository,
  fourPsComparisonAnalysis: fourPsComparisonAnalysisRepository,
  businessModelCanvas     : businessModelCanvasRepository,
  userinsightInfo         : userinsightInfoRepository,
  planningInfo            : planningInfoRepository,
  designInfo              : designInfoRepository,
  salesforce              : salesforceRepository,
  slides                  : slidesRepository,
  ogp                     : ogpRepository,
  departments             : departmentsRepository,
  howNice                 : howNiceRepository,
  overview                : overviewRepository,
  systemSettingRepository : systemSettingRepository,
  section                 : sectionRepository,
  evaluation              : evaluationRepository,
}

export const RepositoryFactory = {
  get: name => repositories[name]
}

