/**
 * @fileoverview SWOT分析 WebAPI の Repository です。
 * SWOT分析 に関するCRUD操作を定義します。
 */
import Repository from '../BaseRepository'
import setup from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor', Repository);
}

export default {

  /**
   * SWOT分析 の取得
   *
   * @param id Get target projectId
   * @returns {*}
   */
  fetchedSwotAnalysis(id) {
    console.log('fetchedSwotAnalysis', Repository.get, '\n', 'Parameters:', id)
    return Repository.get(`${resource}/${id}/swot_analysis`)
  },

  /**
   * SWOT分析 の更新
   *
   * @param projectId Update target projectId
   * @param swotAnalysisRequest Post Request Parameter
   * @returns {*}
   */
  updated(projectId, swotAnalysisRequest) {
    const payload =
      swotAnalysisRequest.name === 'SwotAnalysisRequest'
        ? JSON.stringify(swotAnalysisRequest) : swotAnalysisRequest;
    console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
    return Repository.post(`${resource}/${projectId}/swot_analysis`, payload)
  },

}
