/**
 * @fileoverview ログイン WebAPI の Repository です。
 * ログイン に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'
import { encrypt } from "../../utils/Encryption";

const resource = '/login'

const interceptorManager = Repository.interceptors.request
console.log('interceptorManager(loginRepository)', interceptorManager);
const id = interceptorManager.handlers.length
console.log('interceptorManager(loginRepository:id)', id);
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor(loginRepository)', Repository);
}

export default {

  /**
   * ログイン可能かを問い合わせる
   *
   * @param params Get Parameter
   * @returns {*}
   */
  canLogin (params) {
    //console.log('loginRepository.canLogin=>', Repository, 'Parameters:', params)
    console.log('loginRepository.canLogin=>', 'Parameters:', params)
    let paramsEmail = ''
    let paramsencryptedPassword = ''
    let paramsIv = ''
    if (params) {
      paramsEmail = params.eMail
      if (params.password) {
        // パスワードを暗号化します
        const encrypted = encrypt(params.password);
        paramsencryptedPassword = encrypted.encryptedData
        paramsIv = encrypted.ivRetvalue
      }
    }
    return Repository.post(`${resource}`, {eMail: paramsEmail, password: paramsencryptedPassword, iv: paramsIv})
  },

  /**
   * アクセストークンからログインユーザーのユーザー情報を取得する.
   * @returns {*}
   */
  hasLogin() {
    return Repository.get('/haslogin')
  },

}
