/**
 * @fileoverview 5Force分析 WebAPI の Repository です。
 * 5Force分析 に関するCRUD操作を定義します。
 */
import Repository from '../BaseRepository'
import setup from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor', Repository);
}

export default {

  /**
   * 5Force分析 の取得
   *
   * @param id Get target projectId
   * @returns {*}
   */
  fetchedFiveForcesAnalysis(id) {
    console.log('fetchedFiveForcesAnalysis', Repository.get, '\n', 'Parameters:', id)
    return Repository.get(`${resource}/${id}/five_forces_analysis`)
  },

  /**
   * 5Force分析 の更新
   *
   * @param projectId Update target projectId
   * @param fiveForcesAnalysisRequest Post Request Parameter
   * @returns {*}
   */
  updated(projectId, fiveForcesAnalysisRequest) {
    const payload =
      fiveForcesAnalysisRequest.name === 'FiveForcesAnalysisRequest'
        ? JSON.stringify(fiveForcesAnalysisRequest) : fiveForcesAnalysisRequest;
    console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
    return Repository.post(`${resource}/${projectId}/five_forces_analysis`, payload)
  },

}
