/**
 * @fileoverview プランニング WebAPI の Repository です。
 * プランニング に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
    setup(Repository);
    console.log('Setup Interceptor', Repository);
}

export default {

    /**
     * プランニング の取得
     *
     * @param projectId Get target projectId
     * @returns {*}
     */
    fetchedBrandInfo(projectId) {
        console.log('fetchedBrandInfo:', Repository.get, '\nParameters:', projectId)
        return Repository.get(`${resource}/${projectId}/planning_info`)
    },

    /**
     * プランニング の更新
     *
     * @param projectId Update target projectId
     * @param planningInfoRequest Post Request Parameter
     * @returns {*}
     */
    updated(projectId, planningInfoRequest) {
        const payload =
          planningInfoRequest.name === 'PlanningInfoRequest'
            ? JSON.stringify(planningInfoRequest) : planningInfoRequest;
        console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
        return Repository.post(`${resource}/${projectId}/planning_info`, payload)
    },

}
