export const userInfo_test = {
  //PJ関係者
  userId: 1,
  userName: '山田 太郎',
  lastName: '山田',
  firstName: '太郎',
  position: 'リーダー',
  superUser: 0,
  userImageUrl: 'https://cdn.vuetifyjs.com/images/john.jpg',
  userColor: '#f00',
  departmentName: '営業部',
  loginEmail: 'xxx@xxx.xxx.com',

  //PJ関係者以外
  // userId: 5,
  // userName: "鈴木 一郎",
  // lastName: '鈴木',
  // firstName: '一郎',
  // position: 'リーダー',
  // superUser: 0,
  // userImageUrl: "https://randomuser.me/api/portraits/men/34.jpg",
  // userColor: null,
  // department: "営業部",
  // loginEmail: 'xxx@xxx.xxx.com',
}

export const project_test = {
  id: 1,
  status: 2,
  projectName: 'プロジェクトXXXXX',
  projectIconUrl: 'https://cdn.vuetifyjs.com/images/john.jpg',
  clientName: 'XXXX医院',
  businessName: 'AAAデンタルクリニック',
  industry: '歯科',
  businessPhase: '新規開業',
  siteUrl: 'https://www.yahoo.co.jp/',
  analyticsUrl: 'https://analytics.google.com/analytics/web/',
  "issue": [
      {
          "projectId": 1,
          "issue": "集患強化",
      },
      {
          "projectId": 1,
          "issue": "自費売上UP",
      }
  ],
  affiliatedUser: [
    {
      userId: 1,
      userName: '山田 太郎',
      lastName: '山田',
      firstName: '太郎',
      userImageUrl: 'https://cdn.vuetifyjs.com/images/john.jpg',
      userColor: '#f00',
      departmentName: '営業部',
      loginEmail: 'xxx@xxx.xxx.com',
      seq: 1
    },
    {
      userId: 2,
      userName: '山田 花子',
      lastName: '山田',
      firstName: '花子',
      userImageUrl: null,
      userColor: '#f00',
      departmentName: '開発部',
      loginEmail: 'xxx@xxx.xxx.com',
      seq: 2
    },
    {
      userId: 3,
      userName: '田中 太郎',
      lastName: '田中',
      firstName: '太郎',
      userImageUrl: null,
      userColor: '#f00',
      departmentName: '営業部',
      loginEmail: 'xxx@xxx.xxx.com',
      seq: 3
    },
    {
      userId: 4,
      userName: '佐藤 五郎',
      lastName: '佐藤',
      firstName: '五郎',
      userImageUrl: null,
      userColor: '#f00',
      departmentName: '開発部',
      loginEmail: 'xxx@xxx.xxx.com',
      seq: 4
    },
  ],
  salesforceUrl: 'http://abc.com',
  salesforceClientName: 'Salesforce連携 顧客名',
  salesforceTradeName: 'Salesforce連携 屋号名',
  salesforceCustomerCode: 'Salesforce連携 顧客コード',
  salesforceRegion: 'Salesforce連携 地域',
  salesforceAddress1: 'Salesforce連携 住所１',
  salesforceAddress2: 'Salesforce連携 住所２',
  salesforceWebsite: 'Salesforce連携 ホームページURL',
  salesforceSales: 'Salesforce連携 売上高',
  salesforceEmployeeNumType: 'Salesforce連携 従業員数区分',
  salesforceBtIndustryClassification: 'Salesforce連携 業種分類',
  salesforceBusinessCategoryName1: 'Salesforce連携 業態１',
  salesforceBusinessCategoryName2: 'Salesforce連携 業態２',
  salesforceLbcStory: 'Salesforce連携 ストーリー',
  salesforceLbcBestIndustry: 'Salesforce連携 良業種',
  projectHowNiceCount: 2,
  userHowNiceFlg: false,
  brandStatus: 'edit',
  flameworkStatus: 'edit',
  userinsightStatus: 'edit',
  designStatus: 'edit',
  planningStatus: 'edit',
  createdAt: null,
  createdUser: null,
  createdUserName: null,
  updatedAt: null,
  updatedUser: null,
  updatedUserName: null,
}

export const projects_list_test = {
  projects: [
    {
      id: 1,
      status: 1,
      projectName: 'プロジェクトXXXXX',
      projectIconUrl: 'https://cdn.vuetifyjs.com/images/john.jpg',
      clientName: 'XXXX医院',
      businessName: 'AAAデンタルクリニック',
      industry: '歯科',
      businessPhase: '新規開業',
      siteUrl: 'https://www.yahoo.co.jp/',
      analyticsUrl: 'https://analytics.google.com/analytics/web/',
      issue: ["集患強化", "自費売上UP", "採用強化", "ブランディング"],
      affiliatedUser: [
        {
          userId: 1,
          userName: '山田 太郎',
          lastName: '山田',
          firstName: '太郎',
          userImageUrl: 'https://cdn.vuetifyjs.com/images/john.jpg',
          userColor: '#f00',
          departmentName: '営業部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 1
        },
        {
          userId: 2,
          userName: '山田 花子',
          lastName: '山田',
          firstName: '花子',
          userImageUrl: null,
          userColor: '#f00',
          departmentName: '開発部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 2
        },
        {
          userId: 3,
          userName: '田中 太郎',
          lastName: '田中',
          firstName: '太郎',
          userImageUrl: null,
          userColor: '#f00',
          departmentName: '営業部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 3
        },
        {
          userId: 4,
          userName: '佐藤 五郎',
          lastName: '佐藤',
          firstName: '五郎',
          userImageUrl: null,
          userColor: '#f00',
          departmentName: '開発部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 4
        },
      ],
      salesforceUrl: 'http://abc.com',
      salesforceClientName: 'Salesforce連携 顧客名',
      salesforceTradeName: 'Salesforce連携 屋号名',
      salesforceCustomerCode: 'Salesforce連携 顧客コード',
      salesforceRegion: 'Salesforce連携 地域',
      salesforceAddress1: 'Salesforce連携 住所１',
      salesforceAddress2: 'Salesforce連携 住所２',
      salesforceWebsite: 'Salesforce連携 ホームページURL',
      salesforceSales: 'Salesforce連携 売上高',
      salesforceEmployeeNumType: 'Salesforce連携 従業員数区分',
      salesforceBtIndustryClassification: 'Salesforce連携 業種分類',
      salesforceBusinessCategoryName1: 'Salesforce連携 業態１',
      salesforceBusinessCategoryName2: 'Salesforce連携 業態２',
      salesforceLbcStory: 'Salesforce連携 ストーリー',
      salesforceLbcBestIndustry: 'Salesforce連携 良業種',
      projectHowNiceCount: 2,
      userHowNiceFlg: false,
      brandStatus: 'edit',
      flameworkStatus: 'edit',
      userinsightStatus: 'edit',
      designStatus: 'edit',
      planningStatus: 'edit',
      createdAt: '2021-01-10 00:00:00',
      createdUser: 1,
      createdUserName: '山田 太郎',
      updatedAt: '2021-03-02 00:00:00',
      updatedUser: 1,
      updatedUserName: '山田 太郎',
    },
    {
      id: 2,
      status: 0,
      projectName: 'プロジェクトAA',
      projectIconUrl: null,
      clientName: '医療法人いいい',
      businessName: 'いいい歯科',
      industry: '歯科',
      businessPhase: '新規開業',
      siteUrl: null,
      analyticsUrl: 'https://analytics.google.com/analytics/web/',
      issue: ["集患強化", "ブランディング"],
      affiliatedUser: [
        {
          userId: 4,
          userName: '佐藤 五郎',
          lastName: '佐藤',
          firstName: '五郎',
          userImageUrl: null,
          userColor: '#f00',
          departmentName: '開発部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 1
        },
        {
          userId: 12,
          userName: '田部井 美智子',
          lastName: '田部井',
          firstName: '美智子',
          userImageUrl: 'https://randomuser.me/api/portraits/women/21.jpg',
          userColor: null,
          departmentName: '営業部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 2
        },
      ],
      salesforceUrl: 'http://def.com',
      salesforceClientName: 'Salesforce連携 顧客名',
      salesforceTradeName: 'Salesforce連携 屋号名',
      salesforceCustomerCode: 'Salesforce連携 顧客コード',
      salesforceRegion: 'Salesforce連携 地域',
      salesforceAddress1: 'Salesforce連携 住所１',
      salesforceAddress2: 'Salesforce連携 住所２',
      salesforceWebsite: 'Salesforce連携 ホームページURL',
      salesforceSales: 'Salesforce連携 売上高',
      salesforceEmployeeNumType: 'Salesforce連携 従業員数区分',
      salesforceBtIndustryClassification: 'Salesforce連携 業種分類',
      salesforceBusinessCategoryName1: 'Salesforce連携 業態１',
      salesforceBusinessCategoryName2: 'Salesforce連携 業態２',
      salesforceLbcStory: 'Salesforce連携 ストーリー',
      salesforceLbcBestIndustry: 'Salesforce連携 良業種',
      projectHowNiceCount: 10,
      userHowNiceFlg: false,
      brandStatus: 'done',
      flameworkStatus: 'edit',
      userinsightStatus: 'notInput',
      designStatus: 'notInput',
      planningStatus: 'notInput',
      createdAt: '2021-01-11 00:00:00',
      createdUser: 12,
      createdUserName: '田部井 美智子',
      updatedAt: '2021-03-05 00:00:00',
      updatedUser: 12,
      updatedUserName: '田部井 美智子',
    },
    {
      id: 3,
      status: 1,
      projectName: 'プロジェクトBB',
      projectIconUrl: null,
      clientName: '医療法人ううう',
      businessName: 'uuu歯科',
      industry: '歯科',
      businessPhase: 'フェーズ2',
      siteUrl: null,
      analyticsUrl: 'https://analytics.google.com/analytics/web/',
      issue: ["集患強化", "自費売上UP"],
      affiliatedUser: [
        {
          userId: 7,
          userName: '渡辺 典男',
          lastName: '渡辺',
          firstName: '典男',
          userImageUrl: 'https://randomuser.me/api/portraits/men/30.jpg',
          userColor: null,
          departmentName: '開発部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 1
        },
        {
          userId: 9,
          userName: '矢野 和弘',
          lastName: '矢野',
          firstName: '和弘',
          userImageUrl: 'https://randomuser.me/api/portraits/men/40.jpg',
          userColor: null,
          departmentName: '営業部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 2
        },
      ],
      salesforceUrl: 'http://ghi.com',
      salesforceClientName: 'Salesforce連携 顧客名',
      salesforceTradeName: 'Salesforce連携 屋号名',
      salesforceCustomerCode: 'Salesforce連携 顧客コード',
      salesforceRegion: 'Salesforce連携 地域',
      salesforceAddress1: 'Salesforce連携 住所１',
      salesforceAddress2: 'Salesforce連携 住所２',
      salesforceWebsite: 'Salesforce連携 ホームページURL',
      salesforceSales: 'Salesforce連携 売上高',
      salesforceEmployeeNumType: 'Salesforce連携 従業員数区分',
      salesforceBtIndustryClassification: 'Salesforce連携 業種分類',
      salesforceBusinessCategoryName1: 'Salesforce連携 業態１',
      salesforceBusinessCategoryName2: 'Salesforce連携 業態２',
      salesforceLbcStory: 'Salesforce連携 ストーリー',
      salesforceLbcBestIndustry: 'Salesforce連携 良業種',
      projectHowNiceCount: 22,
      userHowNiceFlg: false,
      brandStatus: 'done',
      flameworkStatus: 'done',
      userinsightStatus: 'edit',
      designStatus: 'edit',
      planningStatus: 'edit',
      createdAt: '2021-02-01 00:00:00',
      createdUser: 9,
      createdUserName: '矢野 和弘',
      updatedAt: '2021-04-02 00:00:00',
      updatedUser: 7,
      updatedUserName: '渡辺 典男',
    },
    {
      id: 4,
      status: 4,
      projectName: 'プロジェクトCCC',
      projectIconUrl: null,
      clientName: '医療法人えええ',
      businessName: 'eデンタルクリニック',
      industry: '歯科',
      businessPhase: 'フェーズ3',
      siteUrl: 'https://yahoo.co.jp',
      analyticsUrl: 'https://analytics.google.com/analytics/web/',
      issue: ["自費売上UP", "採用強化"],
      affiliatedUser: [
        {
          userId: 8,
          userName: '佐々木 次郎',
          lastName: '佐々木',
          firstName: '次郎',
          userImageUrl: 'https://randomuser.me/api/portraits/men/69.jpg',
          userColor: null,
          departmentName: '開発部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 1
        },
        {
          userId: 10,
          userName: '園田 幸子',
          lastName: '園田',
          firstName: '幸子',
          userImageUrl: 'https://randomuser.me/api/portraits/women/20.jpg',
          userColor: null,
          departmentName: '営業部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 2
        },
      ],
      salesforceUrl: 'http://jkl.com',
      salesforceClientName: 'Salesforce連携 顧客名',
      salesforceTradeName: 'Salesforce連携 屋号名',
      salesforceCustomerCode: 'Salesforce連携 顧客コード',
      salesforceRegion: 'Salesforce連携 地域',
      salesforceAddress1: 'Salesforce連携 住所１',
      salesforceAddress2: 'Salesforce連携 住所２',
      salesforceWebsite: 'Salesforce連携 ホームページURL',
      salesforceSales: 'Salesforce連携 売上高',
      salesforceEmployeeNumType: 'Salesforce連携 従業員数区分',
      salesforceBtIndustryClassification: 'Salesforce連携 業種分類',
      salesforceBusinessCategoryName1: 'Salesforce連携 業態１',
      salesforceBusinessCategoryName2: 'Salesforce連携 業態２',
      salesforceLbcStory: 'Salesforce連携 ストーリー',
      salesforceLbcBestIndustry: 'Salesforce連携 良業種',
      projectHowNiceCount: 22,
      userHowNiceFlg: false,
      brandStatus: 'done',
      flameworkStatus: 'done',
      userinsightStatus: 'done',
      designStatus: 'done',
      planningStatus: 'done',
      createdAt: '2021-03-11 00:00:00',
      createdUser: 8,
      createdUserName: '佐々木 次郎',
      updatedAt: '2021-03-30 00:00:00',
      updatedUser: 10,
      updatedUserName: '園田 幸子',
    },
    {
      id: 5,
      status: 0,
      projectName: 'プロジェクトDDD',
      projectIconUrl: null,
      clientName: '医療法人おおお',
      businessName: 'おおお歯科',
      industry: '歯科',
      businessPhase: 'フェーズ3',
      siteUrl: 'https://yahoo.co.jp',
      analyticsUrl: 'https://analytics.google.com/analytics/web/',
      issue: ["自費売上UP", "採用強化"],
      affiliatedUser: [
        {
          userId: 13,
          userName: "木島 宏一",
          lastName: '木島',
          firstName: '宏一',
          userImageUrl: "https://randomuser.me/api/portraits/men/42.jpg",
          userColor: null,
          department: "営業部",
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 1
        },
        {
          userId: 11,
          userName: "藤谷 誠一",
          lastName: '藤谷',
          firstName: '誠一',
          userImageUrl: "https://randomuser.me/api/portraits/men/41.jpg",
          userColor: null,
          department: "開発部",
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 2
        },
        {
          userId: 8,
          userName: '佐々木 次郎',
          lastName: '佐々木',
          firstName: '次郎',
          userImageUrl: 'https://randomuser.me/api/portraits/men/69.jpg',
          userColor: null,
          departmentName: '開発部',
          loginEmail: 'xxx@xxx.xxx.com',
          seq: 3
        },
      ],
      salesforceUrl: 'http://jkl.com',
      salesforceClientName: 'Salesforce連携 顧客名',
      salesforceTradeName: 'Salesforce連携 屋号名',
      salesforceCustomerCode: 'Salesforce連携 顧客コード',
      salesforceRegion: 'Salesforce連携 地域',
      salesforceAddress1: 'Salesforce連携 住所１',
      salesforceAddress2: 'Salesforce連携 住所２',
      salesforceWebsite: 'Salesforce連携 ホームページURL',
      salesforceSales: 'Salesforce連携 売上高',
      salesforceEmployeeNumType: 'Salesforce連携 従業員数区分',
      salesforceBtIndustryClassification: 'Salesforce連携 業種分類',
      salesforceBusinessCategoryName1: 'Salesforce連携 業態１',
      salesforceBusinessCategoryName2: 'Salesforce連携 業態２',
      salesforceLbcStory: 'Salesforce連携 ストーリー',
      salesforceLbcBestIndustry: 'Salesforce連携 良業種',
      projectHowNiceCount: 22,
      userHowNiceFlg: false,
      brandStatus: 'edit',
      flameworkStatus: 'notInput',
      userinsightStatus: 'notInput',
      designStatus: 'notInput',
      planningStatus: 'notInput',
      createdAt: '2021-04-11 00:00:00',
      createdUser: 8,
      createdUserName: '佐々木 次郎',
      updatedAt: '2021-04-20 00:00:00',
      updatedUser: 13,
      updatedUserName: '木島 宏一',
    },
  ],
}

export const users_list_test = {
  users: [
    {
      userId: 1,
      userName: '山田 太郎',
      lastName: '山田',
      firstName: '太郎',
      position: null,
      userImageUrl: 'https://cdn.vuetifyjs.com/images/john.jpg',
      userColor: '#f00',
      department: '営業部',
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 2,
      userName: '山田 花子',
      lastName: '山田',
      firstName: '花子',
      position: null,
      userImageUrl: null,
      userColor: '#f00',
      department: '開発部',
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 3,
      userName: '田中 太郎',
      lastName: '田中',
      firstName: '太郎',
      position: null,
      userImageUrl: null,
      userColor: '#f00',
      department: '営業部',
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 4,
      userName: '佐藤 五郎',
      lastName: '佐藤',
      firstName: '五郎',
      position: null,
      userImageUrl: null,
      userColor: '#f00',
      department: '開発部',
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 5,
      userName: "鈴木 一郎",
      lastName: '鈴木',
      firstName: '一郎',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/men/34.jpg",
      userColor: null,
      department: "営業部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 6,
      userName: "高橋 和子",
      lastName: '高橋',
      firstName: '和子',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/women/42.jpg",
      userColor: null,
      department: "人事部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 7,
      userName: "渡辺 典男",
      lastName: '渡辺',
      firstName: '典男',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/men/30.jpg",
      userColor: null,
      department: "開発部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 8,
      userName: "佐々木 次郎",
      lastName: '佐々木',
      firstName: '次郎',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/men/69.jpg",
      userColor: null,
      department: "営業部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 9,
      userName: "矢野 和弘",
      lastName: '矢野',
      firstName: '和弘',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/men/40.jpg",
      userColor: null,
      department: "営業部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 10,
      userName: "園田 幸子",
      lastName: '園田',
      firstName: '幸子',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/women/20.jpg",
      userColor: null,
      department: "開発部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 11,
      userName: "藤谷 誠一",
      lastName: '藤谷',
      firstName: '誠一',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/men/41.jpg",
      userColor: null,
      department: "開発部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 12,
      userName: "田部井 美智子",
      lastName: '田部井',
      firstName: '美智子',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/women/21.jpg",
      userColor: null,
      department: "営業部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 13,
      userName: "木島 宏一",
      lastName: '木島',
      firstName: '宏一',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/men/42.jpg",
      userColor: null,
      department: "営業部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 14,
      userName: "植原 千晶",
      lastName: '植原',
      firstName: '千晶',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/women/22.jpg",
      userColor: null,
      department: "開発部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 15,
      userName: "原山 正宏",
      lastName: '原山',
      firstName: '正宏',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/men/43.jpg",
      userColor: null,
      department: "開発部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
    {
      userId: 16,
      userName: "栗 正隆",
      lastName: '栗',
      firstName: '正隆',
      position: null,
      userImageUrl: "https://randomuser.me/api/portraits/men/44.jpg",
      userColor: null,
      department: "営業部",
      loginEmail: 'xxx@xxx.xxx.com',
    },
  ],
}

export const departments_list_test = {
  departments: [
    {
      departmentId: 1,
      departmentName: "営業部",
    },
    {
      departmentId: 2,
      departmentName: "開発部",
    },
    {
      departmentId: 3,
      departmentName: "人事部",
    },
  ],
}

export const brand_test = {
  projectId: 1,
  sheetInfo: {
    createdAt: '2021-02-17 00:00:00',
    createdUser: 1,
    createdUserName: '山田太郎',
    updatedAt: '2021-03-17 00:00:00',
    updatedUser: 1,
    updatedUserName: '山田太郎',
  },
  philosophy: {
    question1: '設問1\naaa\nbbb\nccc\nddd\neee\nfff\nggg\nhhh\niii\njjj\nkkk',
    question2: '設問2',
    question3: '設問3',
    question4: '設問4',
    case1: '設問5',
    case2: '設問6',
    case3: '設問7',
    finalyObjective: '最終的なゴール',
    brandmessage: 'ブランドメッセージ',
    hearingAddItems: [
      {
        label: '追加のヒアリング１',
        text: 'ヒアリング１内容\nーーーーーーーー＊\nーーーーーーーー＊',
        seq: 0,
      },
      {
        label: '追加のヒアリング２',
        text: 'ヒアリング２内容\nーーーーーーーー＊\nーーーーーーーー＊',
        seq: 1,
      }
    ],

    inputStatusPhilosophy: 'edit',
  },
  profile: {
    location1: '東京都渋谷区',
    locationProperty1: '住宅地',
    locationProperty2: '独立医院',
    locationProperty3: '幹線道路',
    locationProperty4: '新規×継承',
    locationReason: '開業場所を選んだ理由',
    annualSales: 36000000,
    monthlySales: 3000000,
    monthlyCustomer: 300,
    dailyCustomer: 10,
    monthlyNewCustomer: 15,
    cancellationCustomer: 3,
    selfFinancedRate: 0.3,
    recallRate: 0.2,
    occupancyRate: 0.2,
    cancellationRate: 0.2,
    regularEmployment: {
      organizationNum1: 5,
      organizationNum2: 5,
      organizationNum3: 5,
      organizationNum4: 5,
      organizationNum5: 10,
    },
    nonRegularEmployment: {
      organizationNum1: 1,
      organizationNum2: 2,
      organizationNum3: 3,
      organizationNum4: 4,
      organizationNum5: 5,
    },

    inputStatusClient: 'done',
  },
  vision: {
    vision1: 'ビジョン１年後',
    vision2: 'ビジョン２年後',
    vision3: 'ビジョン３年後',
    performance1: '売り上げ１年後',
    performance2: '売り上げ２年後',
    performance3: '売り上げ２年後',
    organization1: '組織・医院規模　１年後',
    organization2: '組織・医院規模　２年後',
    organization3: '組織・医院規模　３年後',
    visionEvaluation: '院長自らビジョン設計ができている',

    inputStatusVision: 'edit',
  },
  current: {
    strategicPolicy: '・戦略方針１\n・戦略方針２\n・戦略方針３',
    measuresOnlineAttract: 'https://www.yahoo.co.jp/\nhttps://itp.ne.jp/',
    measuresOfflineAttract: 'https://google.com',
    measuresWeb: ['SEO対策', '予約システム', 'Web広告'],
    measuresReal: ['タウン誌掲載', '駅看板掲載'],
    measuresEvaluation: '戦略はあるが施策実行できてない',

    inputStatusCurrent: 'edit',
  },
  service: {
    addItems: [
      {
        serviceName: "aaa",
        serviceContents: "bbb",
        seq: 0,
      }
    ],

    inputStatusService: 'edit',
  },
  competitors: {
    addItems: [
      {
        targetName: "aaa",
        targetUrl: "https://www.yahoo.co.jp/",
        targetFeatures: "bbb",
        clientInstructed: true,
        seq: 0,
      }
    ],

    inputStatusCompetitive: 'edit',
  },
}

export const swot_test = {
  projectId: 1,
  sheetInfo: {
    createdAt: '2021-01-17 00:00:00',
    createdUser: 2,
    createdUserName: '山田花子',
    updatedAt: '2021-02-17 00:00:00',
    updatedUser: 2,
    updatedUserName: '山田花子',
  },

  swot: {
    swotMatrix: {
      swotStrengths: '強み',
      swotWeakness: '弱み',
      swotOpportunity: '機会',
      swotThreat: '脅威',
      swotStrengthsOpportunity: '強み×機会',
      swotWeaknessOpportunity: '弱み×機会',
      swotStrengthsThreat: '強み×脅威',
      swotWeaknessThreat: '弱み×脅威',
    },
    swotSummary: 'SWOT分析まとめ\n＊＊＊＊＊＊\n＊＊＊＊＊＊',

    inputStatusSwot: 'edit',
  },
}

export const three_cs_test = {
  projectId: 1,
  sheetInfo: {
    createdAt: '2021-01-17 00:00:00',
    createdUser: 2,
    createdUserName: '山田花子',
    updatedAt: '2021-02-17 00:00:00',
    updatedUser: 2,
    updatedUserName: '山田花子',
  },

  threeCs: {
    customerViewpoint: '市場・顧客の観点',
    competitorViewpoint: '競合の観点',
    companyViewpoint: '自社の観点',
    threeCsSummary: '3Cまとめ',

    inputStatusThreeCs: 'edit',
  },
}

export const stp_test = {
  projectId: 1,
  sheetInfo: {
    createdAt: '2021-01-17 00:00:00',
    createdUser: 2,
    createdUserName: '山田花子',
    updatedAt: '2021-02-17 00:00:00',
    updatedUser: 2,
    updatedUserName: '山田花子',
  },

  stp: {
    stpSegmentation: '顧客のセグメント定義',
    stpTarget: '・20代～40代',
    stpPositioningmap: "{\"items\":[{\"left\":467,\"top\":71,\"text\":\" aaa\",\"width\":140,\"height\":140,\"scaleX\":1,\"scaleY\":0.5957446808510638,\"fill\":\"rgba(250,170,170,0.8)\",\"stroke\":\"rgb(255,0,0)\",\"strokeWidth\":1}],\"labels\":[{\"left\":405,\"top\":10,\"text\":\"軸タイトル\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"},{\"left\":405,\"top\":470,\"text\":\"軸タイトル\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"},{\"left\":10,\"top\":230,\"text\":\"軸タイトル\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"},{\"left\":680,\"top\":230,\"text\":\"軸タイトル\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"},{\"left\":159,\"top\":106,\"text\":\"ラベルasdsadas\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"}]}",
    stpSummary: 'STP分析まとめ',

    inputStatusStp: 'edit',
  },
}

export const fiveForces_test = {
  projectId: 1,
  sheetInfo: {
    createdAt: '2021-01-17 00:00:00',
    createdUser: 2,
    createdUserName: '山田花子',
    updatedAt: '2021-02-17 00:00:00',
    updatedUser: 2,
    updatedUserName: '山田花子',
  },

  fiveForces: {
    threatNewEntrants: '新規参入の脅威',
    threatSubstitutes: '代替品の脅威',
    bargainingPowerCustomers: '買い手の交渉力',
    bargainingPowerSuppliers: '売り手の交渉力',
    competitiveRivalry: '業界内の競争',

    inputStatusFiveForces: 'edit',
  },
}

export const pest_test = {
  projectId: 1,
  sheetInfo: {
    createdAt: '2021-01-17 00:00:00',
    createdUser: 2,
    createdUserName: '山田花子',
    updatedAt: '2021-02-17 00:00:00',
    updatedUser: 2,
    updatedUserName: '山田花子',
  },

  pest: {
    pestPolitics: '政治的要因',
    pestEconomy: '経済的要因',
    pestSociety: '社会的要因',
    pestTechnology: '技術的要因',

    inputStatusPest: 'edit',
  },
}

export const user_test = {
  projectId: 1,
  sheetInfo: {
    createdAt: '2021-04-01 00:00:00',
    createdUser: 3,
    createdUserName: '田中太郎',
    updatedAt: '2021-04-10 00:00:00',
    updatedUser: 3,
    updatedUserName: '田中太郎',
  },
  target: {
    mainDemogra: 'メインデモグラ',
    mainFeatures: 'メインターゲット特長',
    mainNeeds: 'メインニーズ',

    subTarget: [
      {
        subDemogra: 'サブデモグラ',
        subFeatures: 'サブターゲット特長',
        subNeeds: 'サブニーズ',
      },
    ],

    inputStatusTarget: 'edit',
  },
  persona: {
    data: [
      {
        personaName: 'persona1',
        imageUrl: 'https://cdn.vuetifyjs.com/images/john.jpg',
        gender: '男性',
        age: '20代前半',
        familyStructure: '父、母、妹',
        job: 'エンジニア',
        incomeYear: '200万～400万未満',
        residenceArea: '東京23区',
        transportationType: ['電車', 'バス'],
        hobbies: '読書',
        senseOfValue: '価値観',
        collectInfo: ['ニュースアプリ', 'TV', 'インターネット', '新聞', 'SNS'],
        features: '特長特長特長',
        needs: 'ニーズニーズニーズ',
        customerJourneyData: { "items": [{ "title": "認知", "journey1": "あｓｄさだｓ", "journey2": null, "journey3": null, "journey4": null, "journey5": null, "journey6": null, "journey7": null, "journey8": null, "journey9": null, "journey10": null, "motivation": [0, 0, 0], "touchpoint1": ["タウン誌掲載", "予約システム"], "touchpoint2": [] }, { "title": "比較検討", "journey1": null, "journey2": null, "journey3": null, "journey4": null, "journey5": null, "journey6": null, "journey7": null, "journey8": null, "journey9": null, "journey10": null, "motivation": [0, 0, 0], "touchpoint1": [], "touchpoint2": [] }, { "title": "予約", "journey1": null, "journey2": null, "journey3": null, "journey4": null, "journey5": null, "journey6": null, "journey7": null, "journey8": null, "journey9": null, "journey10": null, "motivation": [0, 0, 0], "touchpoint1": [], "touchpoint2": [] }], "journeySortList": [{ "title": "行動", "field": "journey1", "type": "text" }, { "title": "タッチポイント\n(オンライン)", "field": "touchpoint1", "type": "touchpoint" }, { "title": "タッチポイント\n(オフライン)", "field": "touchpoint2", "type": "touchpoint" }, { "title": "モチベーション", "field": "motivation", "type": "motivation" }, { "title": "フェーズ", "field": "journey3", "type": "text" }] },
      },
      {
        personaName: 'persona2persona2',
        imageUrl: 'https://randomuser.me/api/portraits/women/76.jpg',
        gender: '女性',
        age: '',
        familyStructure: '',
        job: '',
        incomeYear: '',
        residenceArea: '',
        transportationType: '',
        hobbies: '',
        senseOfValue: '',
        collectInfo: '',
        features: '',
        needs: '',
        customerJourneyData: null,
      },
    ],

    inputStatusPersona: 'edit',
  },
}

export const design_test = {
  sheetInfo: {
    createdAt: '2021-03-25 23:59:59',
    createdUser: 4,
    createdUserName: '佐藤五郎',
    updatedAt: '2021-04-25 23:59:59',
    updatedUser: 4,
    updatedUserName: '佐藤五郎',
  },

  image: {
    logo: {
      logoUrl: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
      logoMeaning: '名前の由来は〇〇〇〇〇〇〇〇〇〇〇〇、□□□□□□□□□□□□□□。\n△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△△です。',
    },
    mainColor: '#123456',
    sub1Color: '#654321',
    sub2Color: '#842313',
    sub3Color: '#871333',
    referenceImages: [
      'https://picsum.photos/500/300?image=1',
      'https://picsum.photos/500/300?image=12',
      'https://picsum.photos/500/300?image=23',
      'https://picsum.photos/500/300?image=34',
    ],
    taste: 'このテイストは〇〇〇〇〇〇〇〇〇〇〇〇、〇〇〇〇〇〇〇〇〇〇。\n\n〇〇〇〇〇〇〇〇による、〇〇〇〇〇〇〇〇です。',
    font: ['丸ゴシック', '明朝体', '行書体', '〇〇カスタム'],
    designKeyword: ['清潔感', '楽しい', 'にぎやか', '伝統的', '派手', '高級感', '元気', '〇〇カスタム',],

    inputStatusDesign: 'edit',
  },

  referencesite: {
    data: [
      {
        siteName: 'サンプル',
        siteUrl: 'https://example.com',
        siteImageUrl: 'https://picsum.photos/500/300?image=10',
        referencePoint: 'ポイントは〇〇です。',
        seq: 1,
      },
      {
        siteName: 'Google',
        siteUrl: 'https://google.com',
        siteImageUrl: 'https://picsum.photos/500/300?image=20',
        referencePoint: 'ポイントは△△△△△△△△△△、△△△△△△△△△△△△△△△△△△△△△△△△△△です。',
        seq: 2,
      },
      {
        siteName: 'Yahoo! Japan',
        siteUrl: 'https://yahoo.co.jp',
        siteImageUrl: 'https://picsum.photos/500/300?image=30',
        referencePoint: 'ポイントは□□□□□□□□□□□□□□□□□□□□□□□□□□です。\n加えて□□□□□□□□□□□□□□□□□と□□□□□□□です。',
        seq: 3,
      },
    ],

    inputStatusReferencesite: 'edit',
  },

  parameter: {
    parameters: [-4, 0, 5, 3, -3, null, null, null],

    inputStatusParameters: 'edit',
  },

  note: {
    designNote: '備考は〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇であり、〇〇〇〇〇〇〇〇とする。\n\nそれに加えて〇〇〇〇〇〇〇〇が〇〇〇〇〇〇となる。\n参考は☓☓☓。',

    inputStatusNote: 'edit',
  },
}

export const plan_test = {
  sheetInfo: {
    createdAt: '2021-05-01 23:59:59',
    createdUser: 1,
    createdUserName: '山田太郎',
    updatedAt: '2021-05-25 23:59:59',
    updatedUser: 1,
    updatedUserName: '山田太郎',
  },

  issue: {
    extraction: '課題抽出の内容\nーーーー＊\nーーーー＊',

    inputStatusIssue: 'edit',
  },

  objective: {
    quantification: '定量化目標の内容\nーーーー＊\nーーーー＊',
    qualification: '定性化目標の内容\nーーーー＊\nーーーー＊',

    inputStatusObjective: 'edit',
  },

  reference: {
    concept: 'コンセプトの内容\nーーーー＊\nーーーー＊',
    vision: 'ビジョンの内容\nーーーー＊\nーーーー＊',
    tagline: 'タグラインの内容\nーーーー＊\nーーーー＊',

    inputStatusReference: 'edit',
  },

  swot: {
    swotMatrix: {
      swotStrengths: '強み',
      swotWeakness: '弱み',
      swotOpportunity: '機会',
      swotThreat: '脅威',
      swotStrengthsOpportunity: '強み×機会',
      swotWeaknessOpportunity: '弱み×機会',
      swotStrengthsThreat: '強み×脅威',
      swotWeaknessThreat: '弱み×脅威',
    },
    swotSummary: 'SWOT分析まとめ\n＊＊＊＊＊＊\n＊＊＊＊＊＊',

    inputStatusSwot: 'edit',
  },

  target: {
    mainDemogra: 'メインデモグラ',
    mainFeatures: 'メインターゲット特長',
    mainNeeds: 'メインニーズ',

    subTarget: [
      {
        subDemogra: 'サブデモグラ',
        subFeatures: 'サブターゲット特長',
        subNeeds: 'サブニーズ',
      },
    ],

    inputStatusTarget: 'edit',
  },
  persona: {
    data: [
      {
        personaName: 'persona1',
        imageUrl: 'https://cdn.vuetifyjs.com/images/john.jpg',
        gender: '男性',
        age: '20代前半',
        familyStructure: '父、母、妹',
        job: 'エンジニア',
        incomeYear: '200万～400万未満',
        residenceArea: '東京23区',
        transportationType: ['電車', 'バス'],
        hobbies: '読書',
        senseOfValue: '価値観',
        collectInfo: ['ニュースアプリ', 'TV', 'インターネット', '新聞', 'SNS'],
        features: '特長特長特長',
        needs: 'ニーズニーズニーズ',
        customerJourneyData: '',
      },
      {
        personaName: 'persona2persona2',
        imageUrl: 'https://randomuser.me/api/portraits/women/76.jpg',
        gender: '女性',
        age: '',
        familyStructure: '',
        job: '',
        incomeYear: '',
        residenceArea: '',
        transportationType: '',
        hobbies: '',
        senseOfValue: '',
        collectInfo: '',
        features: '',
        needs: '',
        customerJourneyData: '',
      },
    ],

    inputStatusPersona: 'edit',
  },

  competitors: {
    addItems: [
      {
        targetName: "aaa",
        targetUrl: "https://www.yahoo.co.jp/",
        targetFeatures: "bbb",
        clientInstructed: true,
        seq: 0,
      }
    ],

    inputStatusCompetitive: 'edit',
  },

  stp: {
    stpSegmentation: '顧客のセグメント定義',
    stpTarget: '・20代～40代',
    stpPositioningmap: "{\"items\":[{\"left\":467,\"top\":71,\"text\":\" aaa\",\"width\":140,\"height\":140,\"scaleX\":1,\"scaleY\":0.5957446808510638,\"fill\":\"rgba(250,170,170,0.8)\",\"stroke\":\"rgb(255,0,0)\",\"strokeWidth\":1}],\"labels\":[{\"left\":405,\"top\":10,\"text\":\"軸タイトル\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"},{\"left\":405,\"top\":470,\"text\":\"軸タイトル\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"},{\"left\":10,\"top\":230,\"text\":\"軸タイトル\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"},{\"left\":680,\"top\":230,\"text\":\"軸タイトル\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"},{\"left\":159,\"top\":106,\"text\":\"ラベルasdsadas\",\"fill\":\"rgb(128,128,128)\",\"stroke\":\"rgb(128,128,128)\"}]}",
    stpSummary: 'STP分析まとめ',

    inputStatusStp: 'edit',
  },
  plan: {
    summary: null,
    inputStatusPlan: 'edit',
  }

}