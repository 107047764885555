/**
 * @fileoverview 画像アップロード WebAPI の Repository です。
 * 画像アップロード に関するCRUD操作を定義します。
 */
import Repository  from '../BaseRepository'
import setup       from '../interceptors/Interceptors'

const resource = '/images'
const resource2 = '/images2'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
    setup(Repository);
    console.log('Setup Interceptor', Repository);
}

export default {

    /**
     * 画像アップロード の登録
     *
     * @param payload Post Parameter
     * @returns {*}
     */
    upload(payload) {
        console.log('upload', Repository.post, 'Parameters:', params)
        return Repository.post(`${resource}`, payload)
    },

    upload2(fileInstance, func) {
        const params = new FormData();
        params.append('file', fileInstance);
        return Repository.post(`${resource}/${func}`, params)
    },

    uploadStp(fileInstance, projectId, seq) {
        const params = new FormData();
        params.append('file', fileInstance);

        if(seq === null) {
            return Repository.post(`${resource}/stp/${projectId}`, params)
        } else {
            return Repository.post(`${resource}/stp/${projectId}/${seq}`, params)
        }
    },

    uploadNoteImage(fileInstance, projectId) {
        const params = new FormData();
        params.append('file', fileInstance);
        return Repository.post(`${resource}/note/image/${projectId}`, params)
    },

    /**
     * 画像アップロード の登録
     *
     * @param payload Post Parameter
     * @returns {*}
     */
    uploadWihtId(payload) {
        console.log('uploadWihtId', Repository.delete, 'ImageId:', imageId, 'Parameters:', params)
        const params = new FormData()
        params.append('file', payload.image)
        params.append('extension', payload.extension)
        params.append('func', payload.func)
        Repository.defaults.headers.post['Content-Type'] = 'multipart/form-data'
        return Repository.post(`${resource}/${payload.func}`, params)
    },

    /**
     * 画像アップロード 削除
     *
     * @param imageId Delete target id
     * @param params Delete parameter
     * @returns {*}
     */
    deleted(imageId, params) {
        console.log('deleted', Repository.delete, 'ImageId:', imageId, 'Parameters:', params)
        return Repository.get(`${resource}/${imageId}`, { data: params} )
    },

}
