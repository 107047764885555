/**
 * @fileoverview BPサービス情報 WebAPI の Repository です。
 * BPサービス情報 に関するCRUD操作を定義します。
 */
 import Repository from '../BaseRepository'
 import setup from '../interceptors/Interceptors'
 
 const resource = '/projects'
 
 const interceptorManager = Repository.interceptors.request
 const id = interceptorManager.handlers.length
 if (!id || id === 0) {
   setup(Repository);
   console.log('Setup Interceptor', Repository);
 }
 
 export default {
 
   /**
    * サービス情報 の取得
    *
    * @param id Get target projectId
    * @returns {*}
    */
   fetched(id) {
     console.log('fetched', Repository.get, '\n', 'Parameters:', id)
     return Repository.get(`${resource}/${id}/bp_service`)
   },
 
   /**
    * サービス情報 の更新
    *
    * @param projectId Update target projectId
    * @param body Post Request Parameter
    * @returns {*}
    */
   updated(projectId, body) {
     console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', body)
     return Repository.post(`${resource}/${projectId}/bp_service`, body)
   },
 
 }
 