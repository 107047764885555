/**
 * @fileoverview 4P4C分析 WebAPI の Repository です。
 * 4P4C分析 に関するCRUD操作を定義します。
 */
import Repository from '../BaseRepository'
import setup from '../interceptors/Interceptors'

const resource = '/projects'

const interceptorManager = Repository.interceptors.request
const id = interceptorManager.handlers.length
if (!id || id === 0) {
  setup(Repository);
  console.log('Setup Interceptor', Repository);
}

export default {

  /**
   * 4P4C分析 の取得
   *
   * @param id Get target projectId
   * @returns {*}
   */
  fetchedFourPsFourCsAnalysis(id) {
    console.log('fetchedFourPsFourCsAnalysis', Repository.get, '\n', 'Parameters:', id)
    return Repository.get(`${resource}/${id}/four_ps_four_cs_analysis`)
  },

  /**
   * 4P4C分析 の更新
   *
   * @param projectId Update target projectId
   * @param fourPsFourCsAnalysisRequest Post Request Parameter
   * @returns {*}
   */
  updated(projectId, fourPsFourCsAnalysisRequest) {
    const payload =
      fourPsFourCsAnalysisRequest.name === 'FourPsFourCsAnalysisRequest'
        ? JSON.stringify(fourPsFourCsAnalysisRequest) : fourPsFourCsAnalysisRequest;
    console.log('updated:', Repository.post, 'Parameters:', projectId, 'Body:', payload)
    return Repository.post(`${resource}/${projectId}/four_ps_four_cs_analysis`, payload)
  },

}
