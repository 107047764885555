import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash';

import actions from './actions';
import { relativeScrollableArea, } from './modules';

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    //### ユーザー情報 ###
    userInfo: null,
    accessToken: null,

    nextPage: null,

    //### プロジェクト ###
    project: null,
    searchConditions: null,
    project_readonly: false,

    //### 部署一覧 ###
    departmentsList: null,

    //### セクション ###
    sections: null,

    //### 登録済セクション一覧 ###
    registeredSections: null,

    //### 登録済セクション ###
    registeredSection: null,

    //### BPシート ###
    initBrand: false,
    brand: null,

    // ### サービス ###
    bpService: null,

    //### 目標数値 ###
    targetValuesData: null,

    //### 戦略設計シート ###
    initSwot: false,
    initThreeCs: false,
    initStp: false,
    initFiveForces: false,
    initPest: false,
    initFourPsFourCs: false,
    initFourPsComparison: false,
    initBusinessModelCanvas: false,

    swotData: null,
    threeCsData: null,
    stpData: null,
    fiveForcesData: null,
    pestData: null,
    fourPsFourCsData: null,
    fourPsComparisonData: null,
    businessModelCanvasData: null,

    //### ユーザ理解 ###
    initUser: false,
    user: null,

    //### デザイン要件 ###
    initDesign: false,
    design: null,

    //### プランニング ###
    initPlan: false,
    plan: null,

    //### 入力サンプル ###
    open_referenceDocument: false,
    referenceDocumentInfo: {top: 0, left: 0, id: 'abc'},
    open_referenceImage: false,
    referenceImageInfo: {top: 0, left: 0, id: 'abc'},

    //### 初期スクロール位置 ###
    initialScrollTarget: null,

    // Toast Message Define
    toast: {
      msg: null,
      color: 'error',
      timeout: 4000
    },

    //TODO
    //ポジショニングマップの保存のイベント着火できれば他の手段に変更
    editablePositioningMap: false,

    //いいねの件数
    howNiceCount: 0,
    //いいねフラグ
    userHowNiceFlg: false,

    // 参照エリアデータ無メッセージ
    msgNoData: "表示するデータがありません。",

  },
  getters: {
    //### ユーザー情報 ###
    userInfo: state => state.userInfo,
    accessToken: state => state.accessToken,
    nextPage: state => state.nextPage,

    // ログインユーザーが編集可能なユーザーかどうか判定する
    // プロジェクトが閲覧モードで開かれている場合、アーカイブされている場合の編集不可の判断もここで行っている。
    hasWritePermission: state => {
      if(state.project_readonly) return false;

      // プロジェクトがアーカイブされていれば編集不可
      if(state.project){
        if(!!state.project.archivedAt) return false;
      }

      //スーパーユーザーなら編集可能
      if (state.userInfo.superUser) {
        console.log("hasWritePermission: TRUE(superuser))")
        return true;
      } else if (state.userInfo && state.project) {
        const userIds = [];
        state.project.affiliatedUser.forEach((e) => userIds.push(e.userId));

        //PJ関係者に含まれていれば編集可能
        if (userIds.includes(state.userInfo.userId)) {
          console.log("hasWritePermission: TRUE(affiliated user)")
          return true;
        } else {
          console.log("hasWritePermission: FALSE(not affiliated user)")
          return false;
        }
      } else {
        console.log("hasWritePermission: FALSE(else)")
        return false;
      }
    },

    //### プロジェクト ###
    project: state => state.project,
    searchConditions: state => state.searchConditions,

    //### 部署一覧 ###
    departmentsList: state => state.departmentsList,

    //### BPシート ###
    initBrand: state => state.initBrand,
    brand: state => state.brand,
    brandTotalStatus: state => {
      if (!state.brand && state.project.brandStatus) {
        return state.project.brandStatus
      } else if (state.brand) {
        const philosophy = state.brand.philosophy.inputStatusPhilosophy
        const profile = state.brand.profile.inputStatusClient
        const vision = state.brand.vision.inputStatusVision
        const current = state.brand.current.inputStatusCurrent
        const service = state.brand.service.inputStatusService
        const competitors = state.brand.competitors.inputStatusCompetitive

        let sectionStatus = [philosophy, profile, vision, current, service, competitors]

        //各セクションのステータスから全体のステータスを決定する
        if (sectionStatus.every((item) => item === 'notInput')) {
          return 'notInput'
        } else if (sectionStatus.every((item) => item === 'done')) {
          return 'done'
        } else {
          return 'edit'
        }

      } else {
        return null
      }
    },

    //### サービス情報 ###
    bpService: state => state.bpService,

    //### 目標数値 ###
    targetValuesData: state => state.targetValuesData,

    //### 戦略設計シート ###
    initSwot: state => state.initSwot,
    swotData: state => state.swotData,

    initThreeCs: state => state.initThreeCs,
    threeCsData: state => state.threeCsData,

    initStp: state => state.initStp,
    stpData: state => state.stpData,

    initFiveForces: state => state.initFiveForces,
    fiveForcesData: state => state.fiveForcesData,

    initPest: state => state.initPest,
    pestData: state => state.pestData,

    initFourPsFourCs: state => state.initFourPsFourCs,
    fourPsFourCsData: state => state.fourPsFourCsData,

    initFourPsComparison: state => state.initFourPsComparison,
    fourPsComparisonData: state => state.fourPsComparisonData,

    initBusinessModelCanvas: state => state.initBusinessModelCanvas,
    businessModelCanvasData: state => state.businessModelCanvasData,

    flameworkTotalStatus: state => {
      if (
        !state.swotData && 
        !state.threeCsData && 
        !state.stpData && 
        !state.fiveForcesData && 
        !state.pestData && 
        !state.fourPsFourCsData && 
        !state.fourPsComparisonData && 
        !state.businessModelCanvasData && 
        state.project.flameworkStatus) {

        return state.project.flameworkStatus

      } else if (state.swotData && state.threeCsData && state.stpData && state.fiveForcesData && state.pestData && state.fourPsFourCsData && state.fourPsComparisonData && state.businessModelCanvasData ) {
        const swot = state.swotData.swot.inputStatusSwot
        const threeCs = state.threeCsData.threeCs.inputStatusThreeCs
        const stp = state.stpData.stp.inputStatusStp
        const fiveForces = state.fiveForcesData.fiveForces.inputStatusFiveForces
        const pest = state.pestData.pest.inputStatusPest
        const fourPsFourCs = state.fourPsFourCsData.fourPsFourCs.inputStatusFourPsFourCs
        const fourPsComparison = state.fourPsComparisonData.fourPsComparison.inputStatusFourPsComparison
        const businessModelCanvas = state.businessModelCanvasData.businessModelCanvas.inputStatusBusinessModelCanvas

        let sectionStatus = [swot, threeCs, stp, fiveForces, pest, fourPsFourCs, fourPsComparison, businessModelCanvas]

        //各セクションのステータスから全体のステータスを決定する
        if (sectionStatus.every((item) => item === 'notInput')) {
          return 'notInput'
        } else if (sectionStatus.every((item) => item === 'done')) {
          return 'done'
        } else {
          return 'edit'
        }

      } else {
        return null
      }
    },

    //### ユーザ理解 ###
    initUser: state => state.initUser,
    user: state => state.user,
    userTotalStatus: state => {
      if (!state.user && state.project.userinsightStatus) {
        return state.project.userinsightStatus
      } else if (state.user) {
        const target = state.user.target.inputStatusTarget
        const persona = state.user.persona.inputStatusPersona

        let sectionStatus = [target, persona]

        //各セクションのステータスから全体のステータスを決定する
        if (sectionStatus.every((item) => item === 'notInput')) {
          return 'notInput'
        } else if (sectionStatus.every((item) => item === 'done')) {
          return 'done'
        } else {
          return 'edit'
        }

      } else {
        return null
      }
    },

    //### デザイン要件 ###
    initDesign: state => state.initDesign,
    design: state => state.design,
    designTotalStatus: state => {
      if (!state.design && state.project.designStatus) {
        return state.project.designStatus
      } else if (state.design) {
        const image = state.design.image.inputStatusDesign
        const referencesite = state.design.referencesite.inputStatusReferencesite
        const parameter = state.design.parameter.inputStatusParameters
        const note = state.design.note.inputStatusNote

        let sectionStatus = [image, referencesite, parameter, note]

        //各セクションのステータスから全体のステータスを決定する
        if (sectionStatus.every((item) => item === 'notInput')) {
          return 'notInput'
        } else if (sectionStatus.every((item) => item === 'done')) {
          return 'done'
        } else {
          return 'edit'
        }

      } else {
        return null
      }
    },

    //### プランニング ###
    initPlan: state => state.initPlan,
    plan: state => state.plan,
    planTotalStatus: state => {
      if (!state.plan && state.project.planningStatus) {
        return state.project.planningStatus
      } else if (state.plan) {
        const issue = state.plan.issue.inputStatusIssue
        const objective = state.plan.objective.inputStatusObjective
        const reference = state.plan.reference.inputStatusReference
        const plan = state.plan.plan.inputStatusPlan

        let sectionStatus = [issue, objective, reference, plan]

        //各セクションのステータスから全体のステータスを決定する
        if (sectionStatus.every((item) => item === 'notInput')) {
          return 'notInput'
        } else if (sectionStatus.every((item) => item === 'done')) {
          return 'done'
        } else {
          return 'edit'
        }

      } else {
        return null
      }
    },

    //### セクション ###
    sections: state => state.sections,

    //### 登録済セクション一覧 ###
    registeredSections: state => state.registeredSections,

    //### 登録済セクション ###
    registeredSection: state => state.registeredSection,

    //### 入力サンプル ###
    open_referenceDocument: state => state.open_referenceDocument,
    referenceDocumentInfo: state => state.referenceDocumentInfo,
    open_referenceImage: state => state.open_referenceImage,
    referenceImageInfo: state => state.referenceImageInfo,

    //### 初期スクロール位置 ###
    initialScrollTarget: state => state.initialScrollTarget,

    //### ポジショニングマップ編集 ###
    editablePositioningMap: state => state.editablePositioningMap,

    //### いいねの件数 ###
    howNiceCount: state => state.howNiceCount,
    //### いいねフラグ ###
    userHowNiceFlg: state => state.userHowNiceFlg,

    //### 参照エリアデータ無メッセージ ###
    msgNoData: state => state.msgNoData,
  },
  mutations: {
    //### ユーザー情報 ###
    userInfo(state, value) {
      state.userInfo = value
    },
    accessToken(state, value) {
      state.accessToken = value
    },
    nextPage(state, value) {
      state.nextPage = value
    },

    //### プロジェクト ###
    project(state, value) {
      state.project = value
    },
    searchConditions(state, value) {
      state.searchConditions = value
    },
    project_readonly(state, value) {
      state.project_readonly = value
    },

    //### 部署一覧 ###
    departmentsList(state, value) {
      state.departmentsList = value
    },

    //### BPシート ###
    initBrand(state, value) {
      state.initBrand = value
    },
    brand(state, value) {
      state.brand = value
    },
    brand_input_status_philosophy(state, value) {
      state.brand.philosophy.inputStatusPhilosophy = value
    },
    brand_input_status_profile(state, value) {
      state.brand.profile.inputStatusClient = value
    },
    brand_input_status_vision(state, value) {
      state.brand.vision.inputStatusVision = value
    },
    brand_input_status_current(state, value) {
      state.brand.current.inputStatusCurrent = value
    },
    brand_input_status_service(state, value) {
      state.brand.service.inputStatusService = value
    },
    brand_input_status_competitors(state, value) {
      state.brand.competitors.inputStatusCompetitive = value
    },

    //### サービス ###
    bpService(state, value) {
      state.bpService = value
    },

    //### 目標数値 ###
    targetValuesData(state, value) {
      state.targetValuesData = value
    },

    //### 戦略設計シート ###
    initSwot(state, value) {
      state.initSwot = value
    },
    initThreeCs(state, value) {
      state.initThreeCs = value
    },
    initStp(state, value) {
      state.initStp = value
    },
    initFiveForces(state, value) {
      state.initFiveForces = value
    },
    initPest(state, value) {
      state.initPest = value
    },
    initFourPsFourCs(state, value) {
      state.initFourPsFourCs = value
    },
    initFourPsComparison(state, value) {
      state.initFourPsComparison = value
    },
    initBusinessModelCanvas(state, value) {
      state.initBusinessModelCanvas = value
    },
    swotData(state, value) {
      state.swotData = value
    },
    threeCsData(state, value) {
      state.threeCsData = value
    },
    stpData(state, value) {
      state.stpData = value
    },
    fiveForcesData(state, value) {
      state.fiveForcesData = value
    },
    pestData(state, value) {
      state.pestData = value
    },
    fourPsFourCsData(state, value) {
      state.fourPsFourCsData = value
    },
    fourPsComparisonData(state, value) {
      state.fourPsComparisonData = value
    },
    businessModelCanvasData(state, value) {
      state.businessModelCanvasData = value
    },
    strategy_input_status_swot(state, value) {
      state.swotData.inputStatusSwot = value
    },
    strategy_input_status_threeC(state, value) {
      state.threeCsData.inputStatusThreeCs = value
    },
    strategy_input_status_stp(state, value) {
      state.stpData.inputStatusStp = value
    },
    strategy_input_status_fiveForces(state, value) {
      state.fiveForcesData.inputStatusFiveForces = value
    },
    strategy_input_status_pest(state, value) {
      state.pestData.inputStatusPest = value
    },
    strategy_input_status_fourPsFourCs(state, value) {
      state.fourPsFourCsData.inputStatusFourPsFourCs = value
    },
    strategy_input_status_fourPsComparison(state, value) {
      state.fourPsComparisonData.inputStatusFourPsComparison = value
    },
    strategy_input_status_businessModelCanvas(state, value) {
      state.businessModelCanvasData.inputStatusBusinessModelCanvas = value
    },

    //### ユーザ理解 ###
    initUser(state, value) {
      state.initUser = value
    },
    user(state, value) {
      state.user = value
    },
    user_input_status_target(state, value) {
      state.user.target.inputStatusTarget = value
    },
    user_input_status_persona(state, value) {
      state.user.persona.inputStatusPersona = value
    },

    //### デザイン要件 ###
    initDesign(state, value) {
      state.initDesign = value
    },
    design(state, value) {
      state.design = value
    },
    design_input_status_design(state, value) {
      state.design.image.inputStatusDesign = value
    },
    design_input_status_referencesite(state, value) {
      state.design.referencesite.inputStatusReferencesite = value
    },
    design_input_status_parameters(state, value) {
      state.design.parameter.inputStatusParameters = value
    },
    design_input_status_note(state, value) {
      state.design.note.inputStatusNote = value
    },

    //### プランニング ###
    initPlan(state, value) {
      state.initPlan = value
    },
    plan(state, value) {
      state.plan = value
    },
    plan_input_status_issue(state, value) {
      state.plan.issue.inputStatusIssue = value
    },
    plan_input_status_objective(state, value) {
      state.plan.objective.inputStatusObjective = value
    },
    plan_input_status_reference(state, value) {
      state.plan.reference.inputStatusReference = value
    },
    plan_input_status_plan(state, value) {
      state.plan.plan.inputStatusPlan = value
    },

    //### セクション ###
    sections(state, value){
      state.sections = value
    },

    //### 登録済セクション一覧 ###
    registeredSections(state, value){
      state.registeredSections = value
    },

    //### 登録済セクション ###
    registeredSection(state, value){
      state.registeredSection = value
    },

    //### 入力サンプル ###
    open_referenceDocument(state, value) {
      state.referenceDocumentInfo = value
      state.open_referenceDocument = true
    },
    close_referenceDocument(state) {
      state.open_referenceDocument = false
    },
    open_referenceImage(state, value) {
      state.referenceImageInfo = value
      state.open_referenceImage = true
    },
    close_referenceImage(state) {
      state.open_referenceImage = false
    },


    initialScrollTarget(state, value) {
      state.initialScrollTarget = value
    },

    // Toast Message をセットする
    setToast(state, payload) {
      state.toast = payload
    },

    //### ポジショニングマップ編集 ###
    editablePositioningMap(state, val) {
      state.editablePositioningMap = val
    },

    //### いいねの件数 ###
    howNiceCount(state, val) {
      state.howNiceCount = val
    },

    //### いいねフラグ ###
    userHowNiceFlg(state, val) {
      state.userHowNiceFlg = val
    },
  },
  actions,
  modules: {
    relativeScrollableArea,
  }
})
