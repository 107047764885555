<template>
  <!-- v-model="setSnackbar" へ書き換え -->
  <v-snackbar
      v-model="setSnackbar"
      top
      text
      :timeout="toast.timeout ? toast.timeout: 2000"
      :color="toast.color"
  >
    {{ this.toast.msg }}
    <template v-slot:action="{ attrs }">
      <v-btn
          v-bind="attrs"
          text
          :color="toast.color"
          @click="resetToast"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    toast() {
      return this.$store.state.toast
    },
    setSnackbar: {
      get() {
        return !!this.toast.msg
      },
      set(val) {
        this.resetToast()
        return val
      }
    }
  },
  methods: {
    resetToast() {
      return this.$store.dispatch('setToastMessage', {msg: null})
    }
  },
}
</script>
